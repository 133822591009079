import { images } from "../../pywImagesComponent/PywImages";
import "./PywHomeComponent.styles.scss";
import {
  brandMessageMember,
  headerBar,
  headerPyw,
  progressBar,
} from "../../pywConstantComponent/PywGuestDesignConstant";
import React, { useState, useEffect } from "react";
// import "../../assets/styles/css/Main.styles.scss";
import {
  headers,
  lmheaders,
  PYWHOME,
  BROWSERNAME,
  PYW_MEMBER,
  LOGIN,
  GTM,
  TRUE,
} from "../../pywConstantComponent/PywConstant";
import {
  loading,
  miniloading,
  PywEmailFeedbackComponent,
} from "../../pywConstantComponent/PywDesignsConstant";
import {
  fetchPrepareDetails,
  fetchMerchantDetails,
  disableButtonClick,
  disableInputs,
} from "../../redux";
import { useSelector, useDispatch } from "react-redux";
import PywError from "../../pywErrorComponent/PywError";
import * as duui from "device-uuid";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getMerchantDetails,
  handleChange,
  handleCitiBackdrop,
  showInfoBackdrop,
  validateGuestEmail,
} from "../../pywUtilComponent/PywUtil";
import PywModalBackdrop from "../../pywModalComponent/PywModalBackdrop";
import CitiModalBackdrop from "../../pywModalComponent/CitiModalBackdrop";
import PywInfomodelbackdrop from "../../pywModalComponent/PywInfomodelbackdrop";
// import { loginHandler } from "../../pywUtilComponent/PywLoginUtil"
// import { Redirect } from "react-router-dom";
import * as rdd from "react-device-detect";
import { Redirect } from "../../pywUtilComponent/PywRedirect";
import {
  getTenderBody,
  getTenderFooter,
} from "../../pywConstantComponent/PywTenderDesignConstants";
import AnalyticsWrapper from "../../pywAnalytics/AnalyticsWrapper";
import { GTMAnalytics } from "../../pywAnalytics/pywGTMAnalytics/Pyw_GTM_Analytics";
import PywTealiumAnalytics from "../../pywAnalytics/PywTealiumAnalytics";
const PywHomeComponent = (props) => {
  const pageData = useSelector((state) => state.pageData);
  const lMorePageData = useSelector((state) => state.LMPageData);
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const [guestEmail, setGuestEmail] = useState("");
  const [isAuthenticateFlag, setIsAuthenticateFlag] = useState(false);
  const [guestEmailError, setGuestEmailError] = useState("");
  const { user, isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const [GTMHit, setGTMHit] = useState(true);
  useEffect(async () => {
    // if (process.env.REACT_APP_LOGOUT_ALLOWED !== "true") {
    //     sessionStorage.clear()
    // }

    const queryString = require("query-string");
    const values = queryString.parse(props.location.search);
    const refid = values.refid;
    // const refid = window.opener === null ? encodeURIComponent(values.refid) : values.refid;
    const cartId = values.cartId;
    const sywr = values.sywr;
    BROWSERNAME.NAME = rdd.browserName;
    const merchantClientId = values.merchant;
    const intcmpID = values.intcmp;
    if (merchantClientId != undefined) {
      sessionStorage.setItem("pywmerchant", merchantClientId);
      //cookie.save('pywmerchant', merchantClientId, {maxAge:parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain:'.shopyourway.com', path:'/' });
    }
    if (intcmpID != undefined) {
      sessionStorage.setItem("intcmpID", intcmpID);
      //cookie.save('intcmpID', intcmpID, {maxAge:parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain:'.shopyourway.com', path:'/' });
    }
    headers.merchantClientId = sessionStorage.getItem("pywmerchant"); //cookie.load("pywmerchant");

    delete values.refid;
    delete values.cartId;
    delete values.merchant;

    const items = [values];
    let requestBody = { items };
    let cartIdOld = sessionStorage.getItem("pywcartId");
    if (
      !sessionStorage.getItem("at0pyw") || //|| (cookie.load("refid") === undefined)
      (refid !== undefined && refid !== sessionStorage.getItem("pywrefid"))
    ) {
      var atpyw =
        Date.now() +
        "" +
        window.performance.timing.navigationStart * window.performance.now() +
        "" +
        Math.floor(Date.now() + Math.random() * Date.now());
      sessionStorage.setItem("at0pyw", atpyw);
      //cookie.save('at0pyw', atpyw, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/'});
    }

    if (sessionStorage.getItem("pywcartId")) {
      headers.cartId = sessionStorage.getItem("pywcartId");
    }

    if (sessionStorage.getItem("pywrefid")) {
      headers.refid = sessionStorage.getItem("pywrefid");
    }

    if (refid !== undefined) {
      let referrer_endpoint = document.referrer;
      if (referrer_endpoint.lastIndexOf("/") == referrer_endpoint.length - 1) {
        referrer_endpoint = referrer_endpoint.substring(
          0,
          referrer_endpoint.lastIndexOf("/")
        );
      }
      sessionStorage.setItem("pywrefid", refid);
      sessionStorage.setItem("referrer_endpoint", referrer_endpoint);
      //cookie.save('pywrefid', refid, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com',  path: '/' });
      //cookie.save('referrer_endpoint', referrer_endpoint, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });

      delete requestBody.items;
      headers.refid = refid;
    }

    if (cartId !== undefined) {
      headers.cartId = cartId;
      sessionStorage.setItem("pywcartId", cartId);
      //cookie.save('pywcartId', cartId, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE),  domain: '.shopyourway.com',  path: '/' });
    }
    // headers.pywstate = PYWHOME + "~~" + sessionStorage.getItem("at0pyw") + duui.DeviceUUID().get();
    //console.log(headers.pywstate )
    window.history.pushState(null, "", window.location.href.split("?")[0]);
    const requestHeaders = {
      headers,
    };
    if (process.env.REACT_APP_LOGOUT_ALLOWED === "true") {
      if (cartId !== undefined && cartId !== cartIdOld) {
        logout({ returnTo: window.location.origin });
      } else {
        dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));
		await getMerchantDetails(dispatch);
      }
    } else {
      if (isAuthenticated) {
        setIsAuthenticateFlag(true);
      } else {
        dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));
        await getMerchantDetails(dispatch);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (
      pageData.isValid &&
      GTMHit &&
      process.env.REACT_APP_GTM_ANALYTICS === TRUE
    ) {
      GTMAnalytics("page", "checkout", {
        ...pageData,
        checkoutStep: "1",
      });
      setGTMHit(false);
    }
  }, [pageData]);

  if (isAuthenticateFlag) {
    return <Redirect to={PYW_MEMBER} push={true} />;
  }

  const modelShow = (e) => {
    document.getElementById("backdrop").classList.add("show");
  };
  const modelClose = (e) => {
    document.getElementById("backdrop").classList.remove("show");
  };
  if (navigate) {
    return <Redirect to={PYW_MEMBER} push={true} />;
  }
  if (pageData.pageError) {
    return <PywError pageData={pageData} />;
  } else if (
    pageData.pageLoading ||
    pageData.isShowLoader ||
    pageData.isDisableButtonClick ||
    pageData.merchantDetailsLoading
  ) {
    return loading();
  } else if (pageData.isValid) {
    sessionStorage.setItem("merchantName", pageData.merchantName);
    return (
      <main className="flex-container ff-open-sans">
        {process.env.REACT_APP_TEALIUM_ANALYTICS === "true" && (
          <PywTealiumAnalytics response={pageData} tealiumEvent="checkout" />
        )}
        {headerPyw(pageData.merchantHeadline)}
        {pageData.sendEmailResponse !== "" &&
          PywEmailFeedbackComponent(pageData.sendEmailResponse)}
        {headerBar(1, pageData)}
        {pageData?.tenderTypes?.includes("APPLYANDBUY") ? (
          <>
            <section>
              <div className="container-wrapper-without-padding member-center-card pt-0 pyw-home-wrapper">
                <div>{progressBar(1)}</div>
                {brandMessageMember("")}
                <div>
                  <div className="selection-card-member">
                    <div>
                      <h3 className="mb-8 title-grey fs-16 ml-117 fw-700 lh-20">
                        Shop Your Way Mastercard®
                        <span className="spc-character-without-font-fusion">
                          &#8225;
                        </span>
                      </h3>
                      <div>
                        <a
                          onClick={(e) => {
                            showInfoBackdrop(e);
                          }}
                          className="info-link"
                          id="infoopenModal"
                        >
                          <img src={images.info} alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="member-selection-card">
                      <img
                        className="homeimg-card"
                        src={images.mastercard}
                        alt=""
                      />

                      <div className="description home-description width-275">
                        <p className="title-grey fs-12 fw-400 lh-18 mb-10">
                          Up to<span className="semi-light-blue"> $225 </span>in
                          statement credits with eligible purchases
                          <span className="T-symbol-sm purchase-T-symbol">
                            †
                          </span>
                          .<b> New Accounts only.</b>{" "}
                          <p className="gotham-font-style-test title-grey fs-12">
                            And, <span className="orange">2%</span> back in
                            points* on this purchase.
                          </p>
                        </p>
                        <h5 className="">
                          {" "}
                          <a
                            onClick={(e) => {
                              handleChange(e);
                            }}
                            id="openModal"
                            className="fs-14"
                          >
                            {" "}
                            <span>
                              &#8225;<span className="T-symbol-sm-t">†</span>
                            </span>
                            *See details and exclusions
                          </a>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <footer className="container-wrapper-without-padding member-footer member-footer-margin mb-30 mt-88">
              <button
                className="btn btn-primary"
                disabled={pageData.continuePaymentButtonDisabled}
                onClick={(e) => {
                  handleCitiBackdrop(e);
                }}
              >
                <b className="fs-16 fw-700 lh-20">
                  {pageData.applyButtonDisabled ? miniloading() : "Apply & Buy"}
                </b>
              </button>
              <div className="separator">
                <span className="fs-14 fw-700 lh-20">or</span>
              </div>
              <button
                className="btn btn-secondary h-52"
                disabled={pageData.inputsDisabled}
                onClick={(e) => {
                  dispatch(disableInputs("continueBtn"));
                  loginWithRedirect({ screen_hint: LOGIN });
                }}
              >
                <b className="title-grey fs-16 lh-20 fw-700">
                  {pageData.continuePaymentButtonDisabled
                    ? miniloading()
                    : "Continue to payment"}
                </b>
              </button>
            </footer>
          </>
        ) : (
          <>
            {getTenderBody()}
            {getTenderFooter()}
          </>
        )}
        <PywModalBackdrop btnflag="citibtn" />
        <CitiModalBackdrop data={pageData.addlAttrs} />
        <PywInfomodelbackdrop btnflag="citibtn" />

        <div id="citiApply" className="backdrop">
          <div className="flex center">
            <div className="modal">
              <section className="modal-body">
                <p className="mb-24 flex center">Apply & Buy in progress...</p>
              </section>
            </div>
          </div>
        </div>
      </main>
    );
  } else {
    return <div />;
  }
};

export default PywHomeComponent;
