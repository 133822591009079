import React, { useState, useEffect } from "react";
import "./PywMemberComponent.styles.scss";
//import { handleClickBanner } from "../assets/scripts/scripts";
import {
  handleChange,
  handleClickBanner,
  handleCitiBackdrop,
  showInfoBackdrop,
  getMerchantDetails,
} from "../../pywUtilComponent/PywUtil";
import { useAuth0 } from "@auth0/auth0-react";
import { images } from "../../pywImagesComponent/PywImages";
// import { Redirect } from "react-router-dom";
import { transferCartDetails, fetchMerchantDetails } from "../../redux/index";
import { useSelector, useDispatch } from "react-redux";
import {
  headers,
  lmheaders,
  PYWMEMBER,
  BROWSERNAME,
  BROWSERNAMEFUSION,
  PYW_CUSTOM_SUMMARY,
  PYW_SUMMARY,
  PYW_POINTS,
} from "../../pywConstantComponent/PywConstant";
import PywModalBackdrop from "../../pywModalComponent/PywModalBackdrop";
import CitiModalBackdrop from "../../pywModalComponent/CitiModalBackdrop";
import PywInfomodelbackdrop from "../../pywModalComponent/PywInfomodelbackdrop";
import {
  progressBar,
  headerPyw,
  headerUserWithPoints,
  headerBars,
  loading,
  brandMessageMember,
} from "../../pywConstantComponent/PywDesignsConstant";
import PywError from "../../pywErrorComponent/PywError";
import * as duui from "device-uuid";
import PywTealiumAnalytics from "../../pywAnalytics/PywTealiumAnalytics";
import * as rdd from "react-device-detect";
import { Redirect } from "../../pywUtilComponent/PywRedirect";
import cookie from "react-cookies";
import AnalyticsWrapper from "../../pywAnalytics/AnalyticsWrapper";
const PywMemberComponent = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const pageData = useSelector((state) => state.pageData);
  const lMorePageData = useSelector((state) => state.LMPageData);
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [customFlag, setCustomFlag] = useState(false);

  useEffect(async () => {
    //sessionStorage.setItem('_popupflag', "false");
    cookie.save("_popupflag", "false", {
      maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE),
      domain: ".shopyourway.com",
      path: "/",
    });
    const userJson = JSON.stringify(user);
    //const userNew = userJson.replaceAll(replaceClaimsUri, "");
    const userNew = userJson.split(process.env.REACT_APP_CLAIMS_URL).join("");
    const user_json = JSON.parse(userNew);
    setUserInfo(user_json);
    const refid = sessionStorage.getItem("pywrefid");
    BROWSERNAME.NAME = rdd.browserName;
    headers.cartId = sessionStorage.getItem("pywcartId");
    headers.refid = sessionStorage.getItem("pywrefid");
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");
    lmheaders.merchantClientId = sessionStorage.getItem("pywmerchant");
    lmheaders.refid = sessionStorage.getItem("pywrefid");
    headers.pywstate =
      PYWMEMBER +
      "~~" +
      sessionStorage.getItem("at0pyw") +
      duui.DeviceUUID().get();
    const requestHeaders = {
      headers,
    };
    let lmRequestBody = {};
    const lmRequestHeaders = {
      headers: lmheaders,
    };
    getAccessTokenSilently().then(async (token) => {
      sessionStorage.setItem("pywauth0AccessToken", token);
      let requestBody = {
        // memberNumber: user_json.member_number,
        // cartId: sessionStorage.getItem('pywcartId'),
        userAccessToken: token,
      };
      await dispatch(transferCartDetails(requestBody, requestHeaders, refid));
    });

    await getMerchantDetails(dispatch);
  }, []);

  const modelShow = (e) => {
    document.getElementById("backdrop").classList.add("show");
  };
  const modelClose = (e) => {
    document.getElementById("backdrop").classList.remove("show");
  };

  const redirectToCustom = () => {
    setCustomFlag(true);
  };
  if (customFlag) {
    return <Redirect to={PYW_CUSTOM_SUMMARY} push={true} />;
  }
  if (navigate) {
    return <Redirect to={PYW_POINTS} push={true} />;
  }
  if (pageData.pageError) {
    return <PywError pageData={pageData} />;
  } else if (pageData.pageLoading || pageData.merchantDetailsLoading) {
    return loading();
  } else if (pageData.isValid) {
    sessionStorage.setItem("merchantName", pageData.merchantName);
    if (
      pageData?.ccDc?.savedCards != undefined &&
      pageData?.ccDc?.savedCards?.length > 0
    ) {
      return <Redirect to={PYW_SUMMARY} push={true} />;
    }
    if (pageData?.tenderTypes?.includes("APPLYANDBUY") === false) {
      return <Redirect to={PYW_CUSTOM_SUMMARY} push={true} />;
    }
    return (
      <main className="ff-open-sans flex-container pyw-member-wrapper">
        {pageData?.cartResponseData?.ccInfo?.map((info) => {
          if (info.provider === "CITI" && info.appliedForCard === "Y") {
            return <Redirect to={PYW_CUSTOM_SUMMARY} push={true} />;
          }
        })}
        {(() => {
          if (
            pageData?.ccDc?.savedCards != undefined &&
            pageData?.ccDc?.savedCards?.length > 0
          ) {
            return <Redirect to={PYW_SUMMARY} push={true} />;
          }
        })()}
        {headerPyw(pageData.merchantHeadline)}

        <section className="ff-open-sans">
          {/* <PywTealiumAnalytics response={pageData} tealiumEvent="checkout" /> */}
          <AnalyticsWrapper
            triggerType="page"
            event="checkout"
            data={{
              ...pageData,
              checkoutStep: 2,
            }}
          />
          <PywError pageData={pageData} />
          {(() => {
            if (
              parseInt(new Date() - new Date(userInfo.created_at)) / 60000 <
                5 &&
              !userInfo.email_verified
            ) {
              return (
                <div className="banner">
                  {pageData.ffm.map((ffm) => {
                    if ("EMAIL" === ffm.type) {
                      return (
                        <p>
                          Welcome {ffm.firstName}! Your Shop Your Way{" "}
                          <br className="br-welcome-msg" />
                          account was successfully created!
                        </p>
                      );
                    }
                  })}

                  <button
                    id="hideBanner"
                    onClick={(e) => {
                      handleClickBanner(e);
                    }}
                  >
                    <img src={images.closewhite} alt="" />
                  </button>
                </div>
              );
            }
          })()}
          {headerUserWithPoints(pageData, userInfo)}
          {headerBars(2, pageData, setNavigate)}
          <div className="container-wrapper-without-padding mt-20 member-center-card">
            {/* <div className="mt-16">
                        {progressBar(2)}
                    </div> */}
            <div>
              <div className="ff-open-sans">
                {brandMessageMember(lMorePageData)}
              </div>
              <div className="selection-card-member">
                <div>
                  <h3 className="mb-12 title-grey fs-16 ml-117 fw-700">
                    Shop Your Way Mastercard®
                    <span className="spc-character-without-font">&#8225;</span>
                  </h3>
                  <div>
                    <a
                      onClick={(e) => {
                        showInfoBackdrop(e);
                      }}
                      className="info-link"
                      id="infoopenModal"
                    >
                      <img src={images.info} alt="" />
                    </a>
                  </div>
                </div>
                <div className="member-selection-card">
                  <img
                    className="homeimg-card"
                    src={images.mastercard}
                    alt=""
                  />

                  <div className="description home-description">
                    <p className="mb-5">
                      <p className="title-grey fs-12 lh-18">
                        Up to<span className="semi-light-blue"> $225 </span>in
                        statement credits with eligible purchases
                        <span className="T-symbol-sm purchase-T-symbol">†</span>
                        .<b> New Accounts only.</b>{" "}
                        <p className="gotham-font-style-test title-grey fs-12">
                          And, <span className="orange">2%</span> back in
                          points* on this purchase.
                        </p>
                      </p>
                    </p>
                    <h5 className="">
                      {" "}
                      <a
                        onClick={(e) => {
                          handleChange(e);
                        }}
                        id="openModal"
                        className="fs-14"
                      >
                        {" "}
                        <span>
                          <span className="T-symbol-lg">&#8225;</span>
                          <span className="T-symbol-sm">† </span>
                        </span>{" "}
                        *See details and exclusions
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="container-wrapper-without-padding member-footer member-footer-margin mb-30">
          {/*<button
                        className="btn btn-primary"
                        onClick={() => setNavigate(true)}>
                        Get Started
                    </button>*/}
          <button
            className="btn btn-primary"
            onClick={(e) => {
              handleCitiBackdrop(e);
            }}
          >
            <b className="fs-16">Apply & Buy</b>
          </button>
          <div className="separator">
            <span className="fs-14">or</span>
          </div>
          <button onClick={redirectToCustom} className="btn btn-secondary h-52">
            <b className="title-grey fs-16">Continue to payment</b>
          </button>
        </footer>
        <PywModalBackdrop btnflag="citibtn" />
        <CitiModalBackdrop data={pageData.addlAttrs} />
        <PywInfomodelbackdrop btnflag="citibtn" />
        <div id="citiApply" className="backdrop">
          <div className="flex center">
            <div className="modal">
              <section className="modal-body">
                <p className="mb-24 flex center">Apply & Buy in progress...</p>
              </section>
            </div>
          </div>
        </div>
      </main>
    );
  } else {
    return <div></div>;
  }
};
export default PywMemberComponent;
