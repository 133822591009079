import React from "react";
import { images } from "../pywImagesComponent/PywImages";
// import '../assets/styles/css/Main.styles.scss';
// import '../assets/styles/css/Spinner.styles.scss';
import {hidePrivacyBackdrop} from "../pywUtilComponent/PywUtil";
const PywPrivacymodelbackdrop =(props) =>  {
     return (
      <div id='privacybackdrop' class="privacybackdrop">
        <div class="modal">
            <div class="modal-header">
                <button class="btn btn-text closeModal" onClick={e => {hidePrivacyBackdrop(e) } }>
                    <img src={images.close} alt="Close" />
                </button>
            </div>
          <section class="modal-body">
            <iframe id="iframePrivacy"  src=""
            width="100%" height="430px" frameBorder="0"></iframe>
          </section>
        </div>
      </div>
    )
  
}
export default PywPrivacymodelbackdrop;
