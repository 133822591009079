import React from "react";
import { images } from "../pywImagesComponent/PywImages";
// import '../assets/styles/css/Main.styles.scss';
// import '../assets/styles/css/Spinner.styles.scss';
import {hideTermBackdrop} from "../pywUtilComponent/PywUtil";
const PywTermmodelbackdrop =(props) =>  {

    return (
      <div id='termbackdrop' class="termbackdrop">
        <div class="modal">
            <div class="modal-header">
                <button class="btn btn-text closeModal" onClick={e => {hideTermBackdrop(e) } }>
                    <img src={images.close} alt="Close" />
                </button>
            </div>
          <section class="modal-body">
            <iframe id="iframeTerm" src="" 
            width="100%" height="430px" frameBorder="0"></iframe>
          </section>
        </div>
      </div>
    )
  
}
export default PywTermmodelbackdrop;
