import React,{Component} from "react";
// import './assets/styles/css/Spinner.styles.scss';
import cookie from "react-cookies";
import {CODE_200,LOADING,headers} from "./pywConstantComponent/PywConstant"

import { connect } from "react-redux";
import { fetchPrepareDetails } from "./redux";
import { bindActionCreators } from "redux";
import * as asyContain from "./redux/pywMemberDetails/pywMemberDetailsActions";

import { Redirect } from "./pywUtilComponent/PywRedirect";

class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
          refid: "",
          cartId: "",
        };
    }

    componentDidMount() {
      const queryString = require("query-string");
      const values = queryString.parse(this.props.location.search);

      this.setState({cartId : this.props.location.search});
      this.setState({refid : window.location.search});
    }

    render() {
      return(<div>{this.state.cartId} <br/> {this.state.refid}</div>)
    }
}
export default Test;

// export default connect(
//   state => ({
//     pageData: state.pageData
//   }),
//   dispatch =>
//     bindActionCreators(
//       {
//         ...asyContain
//       },
//       dispatch
//     )
// )(Test);