export const CODE_100 = '100'
export const CODE_200 = '200'
export const CODE_300 = '300'
export const CODE_400 = '400'

export const TRUE = 'true'
export const FALSE = 'false'

export const LOADING = 'Loading...'
export const WSLOADING = 'Waiting for the response...'
export const CHANGE = 'CHANGE'
export const APPLYBUY = 'Apply & Buy'
export const headers = {
      Accept : "Application/json",
      "Content-Type": "application/json",
      channel : "ONLINE",
      platform :"PYW"
      };
export const lmheaders = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      platform: "PYW",
}
export const ERRORCODES = ['SESSION_INVALID', 'TELLCHK-AUTH-FAILED', 'TELLCHK-DECRYPTION-FAILED', 'INVALID-TRANSACTION','PAYMENT-AUTH-FAILED','rzero.auth.failure','rzero.transaction.not.accepted'];
export const CCERRORCODES = ['payment.creditcard.authorizationfailed','binDetails.retrieval.error', 'tell.bin.card.validation.error', '102', 'sharp.single.auth.tokenization.service.error', '105', 'sharp.single.auth.service.error', '104', 'payment.creditcard.authorizationfailed', '110'];
export const PYWHOME = 'pywhome'
export const PYWMEMBER = 'pywmember'
export const PYWPOINTS = 'pywpoints'
export const PYWCONFIRMATION = 'pywconfirmation'
export const PYWCUSTOMSUMMARY = 'pywcustomsummary'
export const PYWSUMMARY = 'pywsummary'
export const PYWERROR = 'pywerror'
export const PYWCCERROR = 'pywccerror'
export const PYWGUESTMEMBER = 'pywguestmember'
export const PYWCITIRETURN = 'pywcitireturn'
export const PYWGUESTSUMMARY = 'pywguestsummary'
export const PYWDECLINED = 'pywdeclined'
export const PYWLMHOME = 'pywlmhome'
export const PYWLMSUMMARY = 'pywlmsummary'
export const PYWLMMEMBER = 'pywlmmember'
export const PYWLMCITIRETURNSUMMARY = 'pywlmcitireturnsummary'
export const PYWLMGUESTCITIRETURNSUMMARY = 'pywlmguestcitireturnsummary'
export const PYWLMDECLINED = 'pywlmdeclined'

export const DEVICEDID = ''
export const PAYMENTPROCCESSING = 'Payment is in progress... '

export const SUBSCRIPTION = "subscription-"

export const LEARN_MORE_HOME_PATH = '/pyw/lm/home'

export const PYW_GUEST_SUMMARY_PATH = '/pyw/guest/summary'

export const PYW_CITI_CARD_DECLINED = '/pyw/declined'

export const PYW_CITI_RETURN = '/pyw/citi/return'

export const PYW_LM_CITI_RETURN = '/pyw/lm/citi/return'

export const CITI_BACKDROP_PATH = '/pyw/lm/member'

export const PYW_HOME_PATH = '/pyw/home'

export const PYW_REG_MEMBER_HOME_PATH = '/pyw/reg/member/home'

export const PYW_GUEST_MEMBER_HOME_PATH = '/pyw/guest/member/home'

export const PYW_CUSTOM_SUMMARY = '/pyw/custom/summary'

// export const PYW_GUEST_MEMBER = '/pyw/guest/member'

export const PYW_FUSION_VALIDATION = '/pyw/fusion/validation'

export const PYW_SUMMARY = '/pyw/summary'

export const PYW_MEMBER = '/pyw/member'

export const PYW_POINTS = '/pyw/points'

export const PYW_GUEST_CUSTOM_SUMMARY = '/pyw/guest/custom/summary'

export const PYW_CC_ERROR = '/pyw/cc/error'

export const PYW_CONFIRMATION = '/pyw/confirmation'

export const PYW_ERROR = '/pyw/error'

export const PYW_CLOSE = '/pyw/close'

export const PYW_ACTIVATION = "/pyw/sha/activation"

export const PYW_LM_HOME = '/pyw/lm/home'

export const PYW_LM_MEMBER = '/pyw/lm/member'

export const PYW_LM_SUMMARY = '/pyw/lm/summary'

export const PYW_LM_CITI_RETURN_SUMMARY='/pyw/lm/citi/return/summary'

export const PYW_LM_GUEST_CITI_RETURN_SUMMARY='/pyw/lm/guest/citi/return/summary'

export const PYW_LM_DECLINED = '/pyw/lm/declined'

export const CITY_BACKDROP_CITYBANK_SITE_MESSAGE = 'You Are Leaving a Shop Your Way Site and Going to a Citibank Site.'

export const CITY_BACKDROP_PRIVACY_POLICY_MESSAGE = 'That site may have a privacy policy and security that is different from this Shop Your Way site. Shop Your Way and its affilliates are not responsible for the products, services, and content on Citibank site.'

export const CITY_BACKDROP_CONTINUE_MESSAGE = 'Do you want to continue to Citibank site?'

export const EMAIL_VERIFY_HEADER = 'Hey Grace! You need to reset your password.'

export const EMAIL_VERIFY_BODY_1 = 'Your account creation needs to be verified before continuing to checkout.'

export const EMAIL_VERIFY_BODY_2 = 'An email has been sent to validate your account. Check your email to continue.'

export const PYW_UNDER_CONSTRUCTION_MESSAGE = 'Page is under construction'
export const ERROR_CODES = {
      SUCCESS: "00",
      ERROR: "99"
}

export const STATUS_CODES = {
      SUCCESS: "success",
      ERROR: "Invalid request"
}

export const WEBKIT = 'webkit'
export const BROWSERNAME = { };
export const GUEST_USER_EMAIL = { };

export const USER_STATE_UNAUTH = "UNAUTH"
export const USER_STATE_AUTH  = "AUTH"


export const SHOWLOADING  = "showloading"

export const SIGNUP   = "signup"
export const LOGIN  = "login"
export const SUCCESS  = "success"
export const EMAIL_SUCCESS_MESSAGE  = "Password reset link has been successfully sent to your email address."
export const EMAIL_FAILURE_MESSAGE  = "Sorry for the inconvenience, we encountered a temporary issue."

export const GTM = "gtm";
export const TEALIUM = "tealium";