import React, { useState, useEffect } from "react";
// import '../assets/styles/css/Main.styles.scss';
// import '../assets/styles/css/Spinner.styles.scss';
import { generateString, hideCitiBackdrop } from "../pywUtilComponent/PywUtil";
import { openPopup } from "../pywUtilComponent/PywCitiPopupUtil"
import cookie from "react-cookies";
import * as rdd from 'react-device-detect';
import { CITY_BACKDROP_CITYBANK_SITE_MESSAGE, CITY_BACKDROP_CONTINUE_MESSAGE, CITY_BACKDROP_PRIVACY_POLICY_MESSAGE, GUEST_USER_EMAIL, headers, PYW_ERROR, PYW_HOME_PATH, PYW_LM_HOME, PYW_LM_MEMBER, SHOWLOADING } from "../pywConstantComponent/PywConstant";
import { BROWSERNAME } from "../pywConstantComponent/PywConstant";
import { useDispatch } from "react-redux";
import { fetchMemberDetails, transferCartDetails } from "../redux";
import { miniloading } from "../pywConstantComponent/PywDesignsConstant";
const CitiModalBackdrop = (props) => {
  const dispatch = useDispatch();
  const[loader , showLoader] = useState(false)
  const citiretailEvent = (citiRdo) => {
    document.getElementById("citiApply").classList.add("show");
    openPopup(citiRdo);

  };
  // async function TransferCartAPI() {

  //   let requestBody = {
  //     // memberNumber: user_json.member_number,
  //     // cartId: sessionStorage.getItem('pywcartId'),
  //     emailAddress: GUEST_USER_EMAIL.email
  //   };
  //   const refid = sessionStorage.getItem("pywrefid");
  //   headers.cartId = sessionStorage.getItem("pywcartId");
  //   headers.refid = sessionStorage.getItem("pywrefid");
  //   headers.merchantClientId = sessionStorage.getItem("pywmerchant");
  //   // headers.pywstate = PYWMEMBER+"~~"+ sessionStorage.getItem("at0pyw")+duui.DeviceUUID().get();
  //   const requestHeaders = {
  //     headers
  //   };
  //   await dispatch(transferCartDetails(requestBody, requestHeaders, SHOWLOADING));
  // }

  async function getMemberDetailsAPI() {
    
    let requestBody = {
      // emailAddress: GUEST_USER_EMAIL.email,
      isMerchentDetailsRequired: "Y"
    };

    // sessionStorage.setItem("pywlmguestemail",GUEST_USER_EMAIL.email)
    // cookie.save('pywlmguestemail', GUEST_USER_EMAIL.email, {maxAge:parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), path:'/',domain:'.shopyourway.com' });
    const headers = {
      Accept: "Application/json",
      merchantClientId: sessionStorage.getItem("pywlmmerchant"),
      platform: "PYW",
      "Content-Type": "application/json",
  }
    if(sessionStorage.getItem('pywlmrefid')){
      headers.refid = sessionStorage.getItem('pywlmrefid')
    }
    const requestHeaders = {
      headers
    };

    await dispatch(fetchMemberDetails(requestBody, requestHeaders));

  }

  const CitiRedirection = async() => {
    // if (window.location.pathname === PYW_HOME_PATH) {
    //   await TransferCartAPI();  
    // }
    if(window.location.pathname === PYW_LM_HOME){
      await getMemberDetailsAPI();
    }
    if(window.location.pathname !== PYW_ERROR){
      if (window.location.pathname === PYW_LM_MEMBER || window.location.pathname === PYW_LM_HOME) {
        var citiRdo = generateString(30);
        cookie.save('citiRandom', citiRdo, { domain: '.shopyourway.com', path: '/' });
        // hideCitiBackdrop();
        showLoader(true);
        window.location.href = process.env.REACT_APP_CITI_CHECKOUT_URL_LEARN_MORE + "flag=2&citiRandom=" + citiRdo+"&cartId="+sessionStorage.getItem("pywlmusersessionid")+"&refid="+sessionStorage.getItem("pywlmrefid")+"&merchant="+sessionStorage.getItem("pywlmmerchant");
      }
      else {
        var citiRdo = generateString(30);
        cookie.save('citiRandom', citiRdo, { domain: '.shopyourway.com', path: '/' });
        // hideCitiBackdrop();
        showLoader(true);
        if (rdd.isBrowser && props.data !== undefined && props.data.redirect !== undefined && props.data.redirect === '_self') {
          if (BROWSERNAME.NAME === "WebKit") {
            window.location.href = process.env.REACT_APP_CITI_CHECKOUT_URL + "flag=2&citiRandom=" + citiRdo+"&cartId="+sessionStorage.getItem("pywcartId")+"&refid="+sessionStorage.getItem("pywrefid")+"&merchant="+sessionStorage.getItem("pywmerchant");
          } else {
            citiretailEvent(citiRdo);
          }
        } else {
          window.location.href = process.env.REACT_APP_CITI_CHECKOUT_URL + "flag=2&citiRandom=" + citiRdo+"&cartId="+sessionStorage.getItem("pywcartId")+"&refid="+sessionStorage.getItem("pywrefid")+"&merchant="+sessionStorage.getItem("pywmerchant");
        }
      }
    }
    
  };

  return (
    <div id='citibackdrop' class="citibackdrop fixed-position-backdrop" >
      <div class="modal">
        <section class="modal-body">
          <h3 class="mb-24 mt-20 fs-20 fw-700 lh-24 title-grey"> {(() => { return CITY_BACKDROP_CITYBANK_SITE_MESSAGE })()}</h3>
          <p class="mb-12 title-grey"><small class="fs-14 fw-400 lh-20">
            {(() => { return CITY_BACKDROP_PRIVACY_POLICY_MESSAGE })()}
            <br />
            <br />
            {(() => { return CITY_BACKDROP_CONTINUE_MESSAGE })()}</small>
          </p>
          <div class="date-cvv mb-20">
            <button disabled={loader} class="btn-citi btn-secondary mr-5" id="payButton" onClick={e => { hideCitiBackdrop(e) }}><span class="title-grey fw-700 fs-16 lh-20">Cancel</span></button>
            <button disabled={loader} class="btn-citi btn-primary" id="payButton" onClick={() => { CitiRedirection() }}><span class="fw-700 fs-16 lh-20">{loader === true ? miniloading() : "Continue"}</span></button>
          </div>
        </section>
      </div>
    </div>
  )

}
export default CitiModalBackdrop;
