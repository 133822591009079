import React, { useEffect } from "react";
import { images } from "../../pywImagesComponent/PywImages";
import { parseCardValue } from "../../pywUtilComponent/PywUtil";
import { headerPyw } from "../../pywConstantComponent/PywDesignsConstant";
import PywTealiumAnalytics from "../../pywAnalytics/PywTealiumAnalytics";
import AnalyticsWrapper from "../../pywAnalytics/AnalyticsWrapper";
import { GTMAnalytics } from "../../pywAnalytics/pywGTMAnalytics/Pyw_GTM_Analytics";
import { TRUE } from "../../pywConstantComponent/PywConstant";
const Pyw_PaymentConfirm = (props) => {
	useEffect(() => {
		if (process.env.REACT_APP_GTM_ANALYTICS === TRUE) {
			GTMAnalytics("page", "purchase", {
				...props?.pageData?.responseData,
				transaction_id: props?.pageData?.responseData?.omsId,
				merchant_transaction_id: props?.pageData?.responseData?.txnId,
			});
		}
	}, []);
	return (
		<main className="flex-container">
			{headerPyw(props.pageData.merchantHeadline)}
			<section className="container container-wrapper container-wrapper-without-padding p-05">
				{/* {process.env.REACT_APP_TEALIUM_ANALYTICS === "true" && (
					<PywTealiumAnalytics
						response={props.pageData}
						tealiumEvent="purchase"
					/>
				)} */}
				{/* <AnalyticsWrapper
					triggerType="page"
					event="purchase"
					data={{
						...props.pageData,
						memberNumber: props?.pageData?.responseData?.memberNumber,
						transaction_id: props?.pageData?.responseData?.omsId,
						merchant_transaction_id: props?.pageData?.responseData?.txnId,
						...props.pageData?.responseData,
					}}
				/> */}
				<h3 className="center">Payment Successful!</h3>
				<div class="center">
					<img src={images.rightGreen} alt="" />
				</div>
				<div className="card column mt-24 mb-16 pt-24 pb-24">
					<h1 className="light-blue mb-12">
						{props.pageData.summary.currency +
							props.pageData.summary.priceSubTotal}
					</h1>
					<h4>{props.pageData.merchantName}</h4>
				</div>
				<p className="grey60">
					<small>
						You will receive an email shortly containing all of the information
						regarding this transaction.
					</small>
				</p>
				{props.pageData.tenders.map((tender) => {
					if (
						"CC_DC" === tender.tenderType &&
						parseFloat(tender.amountPayable) > 0
					) {
						return (
							<div className="payment-summary mt-32 mb-16">
								<p className="mb-8">
									<span className="light-blue">
										{props.pageData.summary.currency + tender.amountPayable}
									</span>{" "}
									was put on your card ending in{" "}
									{parseCardValue(tender.cardNumber)}.
								</p>
							</div>
						);
					}
				})}
				{(() => {
					if (
						props.pageData.summary.earnPointSummary !== undefined &&
						parseInt(props.pageData.summary.earnPointSummary.totalEarnPoints) >
							0
					) {
						return (
							<p className="payment-summary mb-16 grey60">
								You earned{" "}
								<span className="baby-blue">
									{props.pageData.summary.earnPointSummary.totalEarnPoints}
								</span>{" "}
								Shop Your Way points!
							</p>
						);
					}
				})()}
				{props.pageData.tenders.map((tender) => {
					if (
						"SYW_POINTS" === tender.tenderType &&
						parseFloat(tender.redeemPointsInDollar) > 0
					) {
						return (
							<div class="payment-summary mt-32 mb-16">
								<p class="mb-8">
									You redeemed{" "}
									<span class="baby-blue">{tender.redeemPoints}</span> Shop Your
									Way points.{" "}
								</p>
								<p class="grey60">
									Your new balance is now{" "}
									<span class="baby-blue">{tender.pointsBalance}</span> Shop
									Your Way points.
								</p>
							</div>
						);
					}
				})}
			</section>
			<footer>
				<p className="powered">
					<img src={images.lock} alt="" /> Powered by Shop Your Way
				</p>
			</footer>
		</main>
	);
};
export default Pyw_PaymentConfirm;
