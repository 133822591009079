import cookies from "react-cookies";
import * as rdd from 'react-device-detect';

var authenticationType = "";
var utag_cartId = "";
var utag_txnId = "";
var eventAction = "Checkout Click";
var eventCategory = "PYW Checkout";
var eventLabel = "";
var eventName = "";
var eventValue = "";
var hashedEmail = "";
var intcmp = "";
var membershipType = "Registered";
var url_path_name = window.location.pathname;
var page = window.location.origin + url_path_name;
var pageName = "";

export function utag_global_var(response) {
    url_path_name = window.location.pathname;
    page = window.location.origin + url_path_name;
    pageName = "";

    if (response !== undefined && response.cartId !== undefined) {
        utag_cartId = response.cartId;
    }
    if (response !== undefined && response.txnId !== undefined) {
        utag_txnId = response.txnId;
    }
    if (response !== undefined && response.emailHash !== undefined) {
        hashedEmail = response.emailHash;
    }
    // if (url_path_name !== undefined && url_path_name === '/pyw/home') {
    //     pageName = "pyw_home"
    // }else if (url_path_name !== undefined && url_path_name === '/pyw/member') {
    //     pageName = "pyw_member"
    // }else if (url_path_name !== undefined && url_path_name === '/pyw/points') {
    //     pageName = "pyw_points"
    // }else if (url_path_name !== undefined && (url_path_name === '/pyw/summary' || url_path_name === '/pyw/custom/summary')) {
    //     pageName = "pyw_summary"
    // }else if (url_path_name !== undefined && url_path_name === '/pyw/error') {
    //     pageName = "pyw_error"
    // }else if (url_path_name !== undefined && url_path_name === '/pyw/disInitial') {
    //     pageName = "pyw_disinitial"
    // }else if (url_path_name !== undefined && url_path_name === '/pywInitial') {
    //     pageName = "pyw_initial"
    // }else if (url_path_name !== undefined && url_path_name === '/shs') {
    //     pageName = "pyw_contactless_offer"
    // }else if (url_path_name !== undefined && url_path_name === '/shsdesc') {
    //     pageName = "pyw_contactless_qrcode"
    // }else if (url_path_name !== undefined && url_path_name === '/shsstatus') {
    //     pageName = "pyw_payment_confirm"
    // }else if (url_path_name !== undefined && url_path_name === '/shscanstatus') {
    //     pageName = "pyw_payment_cancel"
    // }else if (url_path_name !== undefined && url_path_name === '/shsonhold') {
    //     pageName = "pyw_payment_onhold"
    // }else if (url_path_name !== undefined && url_path_name === '/pyw/order/cl/confirmation' || '/pyw/confirmation') {
    //     pageName = "pyw_orderconfirmation"
    // }
if(url_path_name !== undefined){
    switch (url_path_name) {
        case '/pyw/home':
            pageName = "pyw_home";
            break;
        case '/pyw/member':
            pageName = "pyw_member"
            break;
        case '/pyw/points':
            pageName = "pyw_points"
            break;
        case  '/pyw/summary':
            pageName = "pyw_summary"
            break;
        case  '/pyw/custom/summary':
            pageName = "pyw_summary"
            break;
        case '/pyw/member':
            pageName = "pyw_member"
            break;
        case '/pyw/error':
            pageName = "pyw_error"
            break;
        case '/pyw/disInitial':
            pageName = "pyw_disinitial"
            break;
        case '/pywInitial':
            pageName = "pyw_initial"
            break;
        case '/shs':
            pageName = "pyw_contactless_offer"
            break;
        case '/shsdesc':
            pageName = "pyw_contactless_qrcode"
            break;
        case  '/shsstatus':
            pageName = "pyw_payment_confirm"
            break;
        case '/shscanstatus':
            pageName = "pyw_payment_cancel"
            break;
        case '/shsonhold':
            pageName = "pyw_payment_onhold"
            break;
        case '/pyw/order/cl/confirmation':
            pageName = "pyw_orderconfirmation"
            break;
        case '/pyw/confirmation':
            pageName = "pyw_orderconfirmation"
            break;
        case '/pyw/declined':
            pageName = "pyw_declined"
            break;
        case '/pyw/guest/summary':
            pageName = "pyw_guest_summary"
            break;
        case 'lm/home':
            pageName = "pyw_lm_home"
            break;
        case 'lm/member':
            pageName = "pyw_lm_member"
            break;
        case '/pyw/lm/declined':
            pageName = "pyw_lm_declined"
            break;
        case '/pyw/lm/summary':
            pageName = 'pyw_lm_summary'
            break;
        case '/pyw/lm/citi/return/summary':
            pageName = 'pyw_lm_citi_return_summary'
            break;
        case '/pyw/lm/guest/citi/return/summary':
            pageName = 'pyw_lm_guest_citi_return_summary'
        case '/pyw/guest/custom/summary':
            pageName = 'pyw_guest_custom_summary'
            break;
        case '/pyw/order/sha/confirmation':
            pageName = "pyw_order_sha_confirmation"
            break;
        case '/pyw/sha/activation':
            pageName = "pyw_sha_activation"
            break;
        default:
            break;
    }
}
    
    const perfData = window.performance.timing;
    const pageLoadTime = perfData.loadEventEnd - perfData.navigationStart;
    var pageLoadTimeSec = (pageLoadTime / 1000) % 60;
    var pagePerformance = pageLoadTimeSec;
    var pywmerchant=cookies.load("pywmerchant")!==undefined?cookies.load("pywmerchant"):"";
     if (!rdd.isBrowser){
        pywmerchant= pywmerchant+"_app";
     }
    var pageType = ("pywext_"+pywmerchant).toLowerCase();
    var screen_title = "Pay your way";
    var siteSection = "";
    var sid = getSid();
    var siteId = getSiteId();
    var siteLanguage = navigator.language;
    var ssoUserId = "";
    var sywUserId = "";
    if (response !== undefined && response.sywrUserId !== undefined) {
        sywUserId = response.sywrUserId;
    }
    var tid = "";
    if (response !== undefined && response.memberNumber !== undefined) {
        tid = response.memberNumber;
    }
    var userType = "Registered";

    var global_utag_data = {
        'authenticationType': authenticationType,
        'checkoutCartId': utag_cartId,
        'errorMessage': '',
        'eventAction': eventAction,
        "eventCategory": eventCategory,
        "eventLabel": eventLabel,
        "eventName": eventName,
        "eventValue": eventValue,
        "hashedEmail": hashedEmail,
        "intcmp": intcmp,
        "membershipType": membershipType,
        "page": page,
        "pageName": pageName,
        "pagePerformance": pagePerformance,
        "pageType": pageType,
        "screen_title": screen_title,
        "sid": sid,
        'siteId': siteId,
        'siteLanguage': siteLanguage,
        "siteSection": siteSection,
        'ssoUserId': ssoUserId,
        'sywUserId': sywUserId,
        'tid': tid,
        'userType': userType
    }

    return global_utag_data;

}

function getSid(){

    var sid = "";
    var s_pers_cookies = cookies.load("s_pers");
    if (s_pers_cookies !== undefined) {
        var s_pers = s_pers_cookies.split(";");
        s_pers.map((s_pers_nodes) => {
            s_pers_nodes = s_pers_nodes.trim();
            var s_pers_nodes_slit = s_pers_nodes.split('=');
            if (s_pers_nodes_slit[0].replace(/ /g, '') === 'campaign') {
                sid = s_pers_nodes_slit[1];
            }
        });
    }
    return sid;
}

function getSiteId(){
    var user_agent = navigator.userAgent.toLowerCase();
    var siteId = "Unspecified";
    if (user_agent.indexOf("android") !== -1) {
        siteId = "Android"
    } else if (user_agent.indexOf("iphone") !== -1) {
        siteId = "iPhone"
    } else if (user_agent.indexOf("mobile") !== -1) {
        siteId = "Mobile"
    } else if (user_agent.indexOf("windows") !== -1 || user_agent.indexOf("mac") !== -1 || user_agent.indexOf("linux") !== -1) {
        siteId = "Web"
    }
    return siteId;
}
