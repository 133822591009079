import React, { useEffect, useState } from "react";
import {
  BROWSERNAME,
  headers,
  LOGIN,
  PYW_MEMBER,
} from "../../pywConstantComponent/PywConstant";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "../../pywUtilComponent/PywRedirect";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchPrepareDetails } from "../../redux";
import { loading } from "../../pywConstantComponent/PywDesignsConstant";
import PywError from "../../pywErrorComponent/PywError";
import { GTMAnalytics } from "../../pywAnalytics/pywGTMAnalytics/Pyw_GTM_Analytics";
import { TRUE } from "sass";
import * as rdd from "react-device-detect";

const PywHomeRegisteredMember = (props) => {
  const pageData = useSelector((state) => state.pageData);
  const dispatch = useDispatch();
  const { user, isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const [redirectToMember, setRedirectToMember] = useState(false);
  const [GTMHit, setGTMHit] = useState(true);
  useEffect(async () => {
    const queryString = require("query-string");
    const values = queryString.parse(props.location.search);
    const refid = values.refid;
    const cartId = values.cartId;
    const sywr = values.sywr;
    BROWSERNAME.NAME = rdd.browserName;
    const merchantClientId = values.merchant;
    const intcmpID = values.intcmp;
    if (merchantClientId != undefined) {
      sessionStorage.setItem("pywmerchant", merchantClientId);
    }
    if (intcmpID != undefined) {
      sessionStorage.setItem("intcmpID", intcmpID);
      //cookie.save('intcmpID', intcmpID, {maxAge:parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain:'.shopyourway.com', path:'/' });
    }
    headers.merchantClientId = sessionStorage.getItem("pywmerchant"); //cookie.load("pywmerchant");

    delete values.refid;
    delete values.cartId;
    delete values.merchant;

    const items = [values];
    let requestBody = { items };
    let cartIdOld = sessionStorage.getItem("pywcartId");
    if (
      !sessionStorage.getItem("at0pyw") || //|| (cookie.load("refid") === undefined)
      (refid !== undefined && refid !== sessionStorage.getItem("pywrefid"))
    ) {
      var atpyw =
        Date.now() +
        "" +
        window.performance.timing.navigationStart * window.performance.now() +
        "" +
        Math.floor(Date.now() + Math.random() * Date.now());
      sessionStorage.setItem("at0pyw", atpyw);
      //cookie.save('at0pyw', atpyw, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/'});
    }

    if (sessionStorage.getItem("pywcartId")) {
      headers.cartId = sessionStorage.getItem("pywcartId");
    }

    if (sessionStorage.getItem("pywrefid")) {
      headers.refid = sessionStorage.getItem("pywrefid");
    }

    if (refid !== undefined) {
      let referrer_endpoint = document.referrer;
      if (referrer_endpoint.lastIndexOf("/") == referrer_endpoint.length - 1) {
        referrer_endpoint = referrer_endpoint.substring(
          0,
          referrer_endpoint.lastIndexOf("/")
        );
      }
      sessionStorage.setItem("pywrefid", refid);
      sessionStorage.setItem("referrer_endpoint", referrer_endpoint);
      //cookie.save('pywrefid', refid, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com',  path: '/' });
      //cookie.save('referrer_endpoint', referrer_endpoint, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });

      delete requestBody.items;
      headers.refid = refid;
    }

    if (cartId !== undefined) {
      headers.cartId = cartId;
      sessionStorage.setItem("pywcartId", cartId);
      //cookie.save('pywcartId', cartId, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE),  domain: '.shopyourway.com',  path: '/' });
    }
    // headers.pywstate = PYWHOME + "~~" + sessionStorage.getItem("at0pyw") + duui.DeviceUUID().get();
    //console.log(headers.pywstate )
    window.history.pushState(null, "", window.location.href.split("?")[0]);
    const requestHeaders = {
      headers,
    };
    dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));
  }, []);
  useEffect(() => {
    if (
      pageData.isValid &&
      GTMHit &&
      process.env.REACT_APP_GTM_ANALYTICS === TRUE
    ) {
      GTMAnalytics("page", "checkout", {
        ...pageData,
        checkoutStep: "1",
      });
      setGTMHit(false);
    }
  }, [pageData]);
  const handleAuthFlow = async () => {
    if (user && isAuthenticated) {
      if (
        user?.email &&
        user?.email === pageData?.addlAttrs?.params?.useremail
      ) {
        setRedirectToMember(true);
      } else {
        logout({ returnTo: window.location.origin + window.location.pathname });
      }
    } else {
      //   sessionStorage.clear();
      // await logout({ returnTo: window.location.href });
      await loginWithRedirect({
        screen_hint: LOGIN,
        login_hint: pageData?.addlAttrs?.params?.useremail,
      });
    }
  };
  if (pageData.pageError) {
    return <PywError pageData={pageData} />;
  } else if (redirectToMember) {
    return <Redirect to={PYW_MEMBER} push={true} />;
  } else if (pageData.pageLoading || pageData.isShowLoader) {
    return loading();
  } else if (pageData.isValid) {
    handleAuthFlow();
    sessionStorage.setItem("merchantName", pageData.merchantName);
    return <div>{loading()}</div>;
  } else {
    return <div></div>;
  }
};

export default PywHomeRegisteredMember;
