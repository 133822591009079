import React, { useEffect } from "react";
import { images } from "../../pywImagesComponent/PywImages";
import {
  getMerchantDetails,
  parseCardValue,
  showPrivacyBackdrop,
  showTermBackdrop,
} from "../../pywUtilComponent/PywUtil";
import {
  headerPyw,
  loading,
} from "../../pywConstantComponent/PywDesignsConstant";
import PywTealiumAnalytics from "../../pywAnalytics/PywTealiumAnalytics";
import AnalyticsWrapper from "../../pywAnalytics/AnalyticsWrapper";
import { GTMAnalytics } from "../../pywAnalytics/pywGTMAnalytics/Pyw_GTM_Analytics";
import {
  headers,
  PYW_ACTIVATION,
  TRUE,
} from "../../pywConstantComponent/PywConstant";
import "./Pyw_OrderStatus.styles.scss";
import PywTermmodelbackdrop from "../../pywModalComponent/PywTermmodelbackdrop";
import PywPrivacymodelbackdrop from "../../pywModalComponent/PywPrivacymodelbackdrop";
import { useDispatch, useSelector } from "react-redux";
import { activateMember, orderConfirmationDetails } from "../../redux";
import PywError from "../../pywErrorComponent/PywError";
import { Redirect } from "../../pywUtilComponent/PywRedirect";
const Pyw_UniversalPaymentConfirm = (props) => {
  const dispatch = useDispatch();
  const pageData = useSelector((state) => state.pageData);
  const queryString = require("query-string");
  const values = queryString.parse(props.location.search);
  const cartId = values.pywid;
  // const refid = values.pywrefid;
  const refid = sessionStorage.getItem("pywrefid");
  useEffect(async () => {
    // const refid = window.opener === null ? encodeURIComponent(values.pywrefid) : values.pywrefid;;
    // const statusType = values.pywmsg;

    let requestBody = {};

    headers.cartId = cartId;
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");
    headers.refid = refid;
    // headers.pywstate = PYWCONFIRMATION + "~~" + sessionStorage.getItem("at0pyw") + duui.DeviceUUID().get();
    const requestHeaders = {
      headers,
    };
    dispatch(orderConfirmationDetails(requestBody, requestHeaders, ""));
    await getMerchantDetails(dispatch);
  }, []);
  useEffect(() => {
    if (process.env.REACT_APP_GTM_ANALYTICS === TRUE) {
      GTMAnalytics("page", "purchase", {
        ...pageData?.responseData,
        transaction_id: pageData?.responseData?.omsId,
        merchant_transaction_id: pageData?.responseData?.txnId,
        active_status:
          pageData?.responseData?.offlineProfile === "Y"
            ? "active"
            : "inactive",
      });
    }
  }, []);
  function activateMemberHandler() {
    const headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      platform: "PYW",
      merchantclientid: sessionStorage.getItem("pywmerchant"),
    };
    if (sessionStorage.getItem("pywrefid")) {
      headers.refid = sessionStorage.getItem("pywrefid");
    }
    if (cartId) {
      headers.cartId = cartId;
    }
    let requestBody = {};
    const requestHeaders = {
      headers,
    };
    dispatch(activateMember(requestBody, requestHeaders));
  }
  if (pageData.pageError) {
    return <PywError pageData={pageData} />;
  } else if (
    pageData.pageLoading ||
    pageData.activateMemberLoading ||
    pageData.merchantDetailsLoading ||
    pageData.activateMemberLoading
  ) {
    return loading();
  } else if (
    pageData.activateMemberSuccess &&
    pageData?.activateMemberResponse
  ) {
    return <Redirect to={PYW_ACTIVATION} push={true} />;
  } else if (pageData.confirmStatus) {
    return (
      <main className="ff-open-sans flex-container">
        {headerPyw(pageData.merchantHeadline)}
        <section className="container container-wrapper container-wrapper-without-padding p-05">
          <h3 className="center">Payment Successful!</h3>
          <div class="center mt-18">
            <img src={images?.rightGreen} alt="" />
          </div>
          <div className="card column mt-24 mb-16 pt-12 pb-12">
            <h1 className="tender-blue fs-14 fw-600">
              {pageData?.responseData?.summary?.currency +
                pageData?.responseData?.summary?.priceSubTotal}
            </h1>
            <h4>{pageData?.responseData?.merchantName}</h4>
          </div>
          <p className="univ-center grey-150 fs-12 lh-16 fw-600 mb-12">
            You will receive an email shortly containing all of the information
            regarding this transaction.
          </p>
          {pageData?.responseData?.tenders.map((tender) => {
            if (
              "CC_DC" === tender?.tenderType &&
              parseFloat(tender?.amountPayable) > 0
            ) {
              const hasSYWPoints = pageData?.responseData?.tenders.some(
                (t) =>
                  "SYW_POINTS" === t?.tenderType &&
                  parseFloat(t?.redeemPointsInDollar) > 0
              );

              return (
                <div className="payment-summary mt-32" key={tender.tenderType}>
                  <p className="univ-center grey90 fw-600">
                    <span className="tender-blue">
                      {pageData?.responseData?.summary?.currency +
                        tender?.amountPayable}
                    </span>{" "}
                    was put on your card ending in{" "}
                    {parseCardValue(tender?.cardNumber)}
                    {hasSYWPoints ? <span> and</span> : <span>.</span>}
                  </p>
                </div>
              );
            }
            // return null;
          })}

          {pageData?.responseData?.tenders.map((tender) => {
            if (
              "SYW_POINTS" === tender?.tenderType &&
              parseFloat(tender?.redeemPointsInDollar) > 0
            ) {
              return (
                <div class="payment-summary mb-16 univ-center">
                  <p class="fw-600">
                    {"CC_DC" === tender?.tenderType &&
                      parseFloat(tender?.amountPayable) > 0}
                    <span class="tender-blue">{tender?.redeemPoints}</span> was
                    put on Shop Your Way points.{" "}
                  </p>
                </div>
              );
            }
          })}

          {(() => {
            if (
              pageData?.responseData?.summary?.earnPointSummary !== undefined &&
              parseInt(
                pageData?.responseData?.summary?.earnPointSummary
                  ?.totalEarnPoints
              ) > 0
            ) {
              return (
                <p className="payment-summary mb-16 grey60 univ-center fw-600">
                  {pageData?.responseData?.offlineProfile === "Y" &&
                    (pageData?.responseData?.clubStatus === "G" ||
                      pageData?.responseData?.clubStatus === "A" ||
                      pageData?.responseData?.clubStatus === "P") ? (
                    <>You can earn{" "}
                      <span className="baby-blue-light">
                        {
                          pageData?.responseData?.summary?.earnPointSummary
                            ?.totalEarnPoints
                        }
                      </span>{" "}
                      Shop Your Way points!</>
                  ) : <>You earned{" "}
                    <span className="baby-blue-light">
                      {
                        pageData?.responseData?.summary?.earnPointSummary
                          ?.totalEarnPoints
                      }
                    </span>{" "}
                    Shop Your Way points!</>}
                </p>
              );
            }
          })()}
          {pageData?.responseData?.offlineProfile === "Y" &&
              (pageData?.responseData?.clubStatus === "G" ||
                pageData?.responseData?.clubStatus === "A"||
                pageData?.responseData?.clubStatus === "P")&& (
            <div className="payment-summary mt-32">
              <p className="mb-8 univ-center grey90 fw-600 title-grey">
                Claim your points by creating your profile.
              </p>
            </div>
          )}
          {/* 
          {pageData?.responseData?.tenders.map((tender) => {
            if (
              "SYW_POINTS" === tender?.tenderType &&
              parseFloat(tender?.redeemPointsInDollar) > 0
            ) {
              return (
                <div class="payment-summary mt-32 mb-16 univ-center">
                  <p class="mb-8">
                    You redeemed{" "}
                    <span class="baby-blue">{tender?.redeemPoints}</span> Shop
                    Your Way points.{" "}
                  </p>
                  <p class="grey60">
                    Your new balance is now{" "}
                    <span class="baby-blue">{tender?.pointsBalance}</span> Shop
                    Your Way points.
                  </p>
                </div>
              );
            }
          })} */}
        </section>
        <footer className="confirm-footer">
          {
           pageData?.responseData?.offlineProfile === "Y" &&
           (pageData?.responseData?.clubStatus === "G" ||
             pageData?.responseData?.clubStatus === "A"||
             pageData?.responseData?.clubStatus === "P") && (
                <button class="btn btn-primary" onClick={activateMemberHandler}>
                  <b>Activate membership</b>
                </button>
              )
            // : (
            //   <a
            //     href={process.env.REACT_APP_CONTINUE_TO_ACCOUNT_URL}
            //     target="_blank"
            //   >
            //     <button class="btn btn-primary">
            //       <b>Continue To Account</b>
            //     </button>
            //   </a>
            // )
          }

          <p className="terms-confirm-page">
            By continuing, you agree to our{" "}
            <span className="tender-blue">
              <a
                onClick={(e) => {
                  showTermBackdrop(
                    e,
                    process.env.REACT_APP_TERM_AND_CONDITION_SYWP_URL
                  );
                }}
                id="openModal"
              >
                Membership Terms{" "}
              </a>
            </span>{" "}
            and{" "}
            <span className="tender-blue">
              {" "}
              <a
                onClick={(e) => {
                  showPrivacyBackdrop(e, process.env.REACT_APP_PRIVACY_URL);
                }}
                id="openModal"
              >
                Privacy Policy.{" "}
              </a>
            </span>
            CA or CO residents also opt-in and consent to the program terms
            described in our{" "}
            <span className="tender-blue">
              {" "}
              <a
                onClick={(e) => {
                  showPrivacyBackdrop(
                    e,
                    process.env.REACT_APP_PRIVACY_URL +
                      process.env.REACT_APP_NOTICE_AND_FINANCIAL_PRM
                  );
                }}
                id="openModal"
              >
                Loyalty Program and Notice of Financial Incentive Disclosure.{" "}
              </a>
            </span>
          </p>
        </footer>
        <PywTermmodelbackdrop />
        <PywPrivacymodelbackdrop />
      </main>
    );
  } else return <div></div>;
};
export default Pyw_UniversalPaymentConfirm;
