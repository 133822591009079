import React, {Component} from 'react'
import lock from '../assets/images/Lock.svg';
import sywLogo from '../assets/images/SYW_logo_black.svg';
import {images} from '../pywImagesComponent/PywImages';
import { handleChange, handleClickBanner, showInfoBackdrop, showPrivacyBackdrop, showTermBackdrop } from "../pywUtilComponent/PywUtil";
import {openPopup} from "../pywUtilComponent/PywCitiHub";
import cookie from "react-cookies";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import PywInitialDynamic from "./PywInitialDynamic";
import axios from "axios";
import { connect } from "react-redux";
import { getSdkContents } from "../redux";
import { bindActionCreators } from "redux";
import * as asyContain from "../redux/pywMemberDetails/pywMemberDetailsActions";
import AnalyticsWrapper from '../pywAnalytics/AnalyticsWrapper';
import "./PywSdkComponent.styles.scss"
class PywInitial extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      intcmpID: '',
      intcmpFlag: false,
      pageData: '',
      payType: ''
    };
  }
  componentDidMount() {
    const queryString = require("query-string");
    const values = queryString.parse(this.props.location.search);
    const merchantClientId = values.merchant;
    const intcmpID = values.intcmp;
    const payType = values.payType;
    const learnMore = values.learnMore;
    if (merchantClientId != undefined) {
      cookie.save('pywmerchant', merchantClientId, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), path: '/' });
    }
    if (intcmpID != undefined) {
      cookie.save('intcmpID', intcmpID, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), path: '/' });
    }
    this.setState({ intcmpID: values.intcmp, payType: values.payType });
    const headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      platform: payType === 'giftcard' ? "GC" : "PYW",
      // learnMore:learnMore,
      merchantClientId: merchantClientId
    };
    let requestHeaders = { headers };
    let requestBody = {};

    this.props.getSdkContents(requestBody, requestHeaders);
  }

  isValidResponse(response) {
    if (response !== undefined && response !== "" &&
      Object.keys(response).length !== 0 && response.CMP_PayYourWay !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { pageError, pageLoading, response } = this.props.pageData;
    if (this.isValidResponse(response)) {
      return (<div className="initial"><PywInitialDynamic pageData={response}/></div>);
    } else if (this.props.pageData.pageLoading) {
      return (
        <div className="initial">
          <section class="container loading-card mt-32">
            <div class="loader" />
            <h5 class="mt-16">Loading...</h5>
          </section>
        </div>
      );
    } else if (pageError !== null) {
      if (this.state.payType == 'giftcard') {
        return (<div/>);
      } else {
        return (
          <div className="initial">
            <div className="description">
              {/* <PywTealiumAnalytics response={this.state.pageData} tealiumEvent="initial" /> */}
              <AnalyticsWrapper
					 
					triggerType="page"
                event="initial"
					data={this.state.pageData}
				/>
              <div className="initial-body pywinitial-card">
                <div className="initial-body-left-line mb-8 master-card-img">
                  <img src={images.mastercard} alt="" />
                  <p>
                    <p className="mb-4 width-16rem"><b>Apply for the Shop Your Way </b>
                      <b> Mastercard<sup>®</sup>, when approved </b>
                      <b> get up to <span className="semi-light-blue"> $225 </span> in statement credits with eligible purchases<sup>†</sup>.</b></p>
                    <p><h5 className="mt-16 width-16rem"> <a onClick={e => { openPopup("") } } id="openModal"> <sup>†</sup>See details and exclusions</a></h5></p>
                  </p>
                </div>
                <div className="initial-body-right">
                  <p>
                    <p className="pywinitial-sdk"><img className=""src={images.lock} alt=""/> Powered by Shop Your Way</p>
                    <h5 >
                      <button className="btninitial btn-primary mt-8" onClick={() => window.parent.postMessage('WINDOW_OPEN', '*') }>
                        <b>Pay with Shop Your Way</b>
                      </button>
                    </h5>
                    <p className="mt-8"><b className="fontSize0_858">And, use the Shop Your Way Mastercard<sup>®</sup>.</b></p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      }
    }
  }
}
export default connect(
  state => ({
    pageData: state.pageData
  }),
  dispatch =>
    bindActionCreators(
      {
        ...asyContain
      },
      dispatch
    )
)(PywInitial);
