import axios from 'axios'
import cookie from 'react-cookies'
import * as rdd from 'react-device-detect';
import * as duui from'device-uuid'
import {
    FETCH_PREPARE_REQUEST,
    FETCH_PREPARE_SUCCESS,
    FETCH_PREPARE_FAILURE,
    TRANSFER_CART_REQUEST,
    TRANSFER_CART_SUCCESS,
    TRANSFER_CART_FAILURE,
    ORDER_CONFIRMATION_REQUEST,
    ORDER_CONFIRMATION_SUCCESS,
    ORDER_CONFIRMATION_FAILURE,
    ORDER_CONFIRMATION_LIGHT_SUCCESS,
    FETCH_ORDERCONFIRMATION_SUCCESS_ERROR,
    FETCH_APPLYPOINTS_REQUEST,
    FETCH_APPLYPOINTS_SUCCESS,
    FETCH_APPLYPOINTS_SUCCESS_ERROR,
    FETCH_APPLYPOINTS_FAILURE,
    FETCH_PLACEORDER_REQUEST,
    FETCH_PLACEORDER_SUCCESS,
    FETCH_PLACEORDER_FAILURE,
    FETCH_PLACEORDER_SUCCESS_ERROR,
    FETCH_CREDITCARD_SUCCESS,
    FETCH_CREDITCARD_FAILURE,
    FETCH_CREDITCARD_SUCCESS_ERROR,
    ADD_SUBSCRIPTION_REQUEST,
    ADD_SUBSCRIPTION_SUCCESS,
    ADD_SUBSCRIPTION_SUCCESS_ERROR,
    ADD_SUBSCRIPTION_FAILURE,
    GENERATE_PAYMENT_REQUEST,
    GENERATE_PAYMENT_SUCCESS,
    GENERATE_PAYMENT_FAILURE,
    GET_SDKCONTENT_REQUEST,
    GET_SDKCONTENT_SUCCESS,
    GET_SDKCONTENT_SUCCESS_ERROR,
    GET_SDKCONTENT_FAILURE,
	VALIDATE_EMAIL_REQUEST,
	VALIDATE_EMAIL_SUCCESS,
	VALIDATE_EMAIL_SUCCESS_ERROR,
	VALIDATE_EMAIL_FAILURE,
    DISABLE_BUTTON_CLICK_TRUE,
    ENABLE_BUTTON_CLICK_TRUE,
    VERIFY_EMAIL_REQUEST,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAILURE,
    VERIFY_EMAIL_SUCCESS_ERROR,
    ENABLE_INPUTS,
    DISABLE_INPUTS,
    FETCH_MERCHANT_DETAIL_FAILURE,
    FETCH_MERCHANT_DETAIL_SUCCESS,
    FETCH_MERCHANT_DETAIL_SUCCESS_ERROR,
    FETCH_MERCHANT_DETAIL_REQUEST,
    CREATE_MEMBER_REQUEST,
    CREATE_MEMBER_SUCCESS,
    CREATE_MEMBER_FAILURE,
    CREATE_MEMBER_SUCCESS_ERROR,
    ACTIVATE_MEMBER_REQUEST,
    ACTIVATE_MEMBER_SUCCESS_ERROR,
    ACTIVATE_MEMBER_SUCCESS,
    ACTIVATE_MEMBER_FAILURE,
} from './pywMemberDetailsTypes'
import { hideCitiBackdrop } from '../../pywUtilComponent/PywUtil';
import { CODE_200, SHOWLOADING, STATUS_CODES, SUCCESS } from '../../pywConstantComponent/PywConstant';

//prepare API Call Start
export const fetchPrepareDetails = (requestBody, requestHeaders, refid) => {
    return (dispatch) => {
        dispatch(fetchPrepareRequest())
        //requestHeaders.headers.forterToken=cookie.load("forterToken");
        axios.post(process.env.REACT_APP_PYW_PREPARE_URL + "?ftoken=" + cookie.load("forterToken"),
            requestBody, requestHeaders
        ).then(resp => {
            if (resp.data.isValid) {
                sessionStorage.setItem('merchantName', resp.data.merchantName);
                dispatch(fetchPrepareSuccess(resp.data))

            }
            else {
                dispatch(fetchPrepareFailure(resp.data))
            }
        })
            .catch(err => {
                // sessionStorage.removeItem("pywcartId");
                // sessionStorage.removeItem("pywrefid");
                dispatch(fetchPrepareFailure(err.message))
            });
    }
}
export const fetchPrepareRequest = () => {
    return {
        type: FETCH_PREPARE_REQUEST
    }
}
export const fetchPrepareSuccess = apiData => {
    return {
        type: FETCH_PREPARE_SUCCESS,
        payload: apiData
    }
}
export const fetchPrepareFailure = error => {
    return {
        type: FETCH_PREPARE_FAILURE,
        payload: error
    }
}
//prepare API Call END

//TransferCart Api Call Start
export const transferCartDetails = (requestBody, requestHeaders, showLoading) => {

    return async (dispatch) => {
        if(!showLoading || showLoading!==SHOWLOADING){
            dispatch(transferCartRequest())
        }else{
            hideCitiBackdrop();
            dispatch(disableInputs("applyBtn"));
        }
        //requestHeaders.headers.forterToken=cookie.load("forterToken");
         try {
            const resp = await axios.post(process.env.REACT_APP_PYW_TRANSFERCART_URL + "?ftoken=" + cookie.load("forterToken"),
            requestBody, requestHeaders)
            if (resp.data.isValid) {
                sessionStorage.setItem('pywcartId', resp.data.cartId)
                dispatch(transferCartSuccess(resp.data));
            }
            else {
                if(resp.data.cartId) sessionStorage.setItem('pywcartId', resp.data.cartId)
                dispatch(transferCartfailure(resp.data));
            }            
         } catch (err) {
            dispatch(enableInputs());
            dispatch(transferCartfailure(err.message))
         }
    }
}

export const transferCartRequest = () => {
    return {
        type: TRANSFER_CART_REQUEST
    }
}
export const transferCartSuccess = apiData => {
    return {
        type: TRANSFER_CART_SUCCESS,
        payload: apiData
    }
}
export const transferCartfailure = error => {
    return {
        type: TRANSFER_CART_FAILURE,
        payload: error
    }
}

//applypoints API Call Start

export const fetchApplyPoints = (sywPointsInDollar, requestOptions) => {
    return (dispatch) => {
        dispatch(fetchApplyPointsRequest())
        //requestOptions.headers.forterToken=cookie.load("forterToken");
        axios.post(process.env.REACT_APP_PYW_APPLY_POINTS_URL + "?ftoken=" + cookie.load("forterToken"),
            { pointsInDollar: sywPointsInDollar },
            requestOptions).then(resp => {
                if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                    dispatch(fetchApplyPointsSuccessError(resp.data))
                } else {
                    dispatch(fetchApplyPointsSuccess(resp.data))
                }
            })
            .catch(err => {
                var data = { "status": "error", "errors": [{ "type": "Apply_Points", "code": "04", "message": "Apply points service is currently unavailable" }] };
                dispatch(fetchApplyPointsSuccessError(data))
            });
    }
}


export const fetchApplyPointsRequest = () => {
    return {
        type: FETCH_APPLYPOINTS_REQUEST
    }
}

export const fetchApplyPointsSuccess = apiData => {
    return {
        type: FETCH_APPLYPOINTS_SUCCESS,
        payload: apiData
    }
}
export const fetchApplyPointsSuccessError = apiData => {
    return {
        type: FETCH_APPLYPOINTS_SUCCESS_ERROR,
        payload: apiData
    }
}
export const fetchApplyPointsFailure = error => {
    return {
        type: FETCH_APPLYPOINTS_FAILURE,
        payload: error
    }
}

//applypoints API Call End

//placeOrder API Call Start
export const fetchPlaceOrder = (requestOptions) => {
    return async (dispatch) => {
        dispatch(fetchPlaceOrderRequest())
        requestOptions.headers.refid = sessionStorage.getItem("pywrefid");
        // requestOptions.headers.roToken = cookie.load("roToken");
        requestOptions.headers.userAgent = rdd.getUA;
        requestOptions.headers.osName = rdd.osName;
        requestOptions.headers.browserName = rdd.browserName;
        requestOptions.headers.deviceType = rdd.deviceType;
        requestOptions.headers.isBrowser = rdd.isBrowser;
        requestOptions.headers.isMobile = rdd.isMobile;
        requestOptions.headers.deviceId = duui.DeviceUUID().get();
        let resp = await axios.get(process.env.REACT_APP_PYW_PLACE_ORDER_URL + "?ftoken=" + cookie.load("forterToken"), requestOptions);
        try {
            if (resp.data !== undefined && resp.data !== '' && resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                dispatch(fetchPlaceOrderSuccessError(resp.data))
            } else {
                //dispatch(fetchPlaceOrderSuccess(resp.data))
                OrderConfirmation(requestOptions, dispatch);
            }
        } catch (err) {
            OrderConfirmation(requestOptions, dispatch);
        }

    }
}


export const fetchPlaceOrderAndUpdateCard = (requestOptions, requestBody) => {
    return async (dispatch) => {
        dispatch(fetchPlaceOrderRequest())
        try {
            let resp = await axios.post(process.env.REACT_APP_PYW_PAYMENT_UPDATE_URL + "?ftoken=" + cookie.load("forterToken"), requestBody, requestOptions)
            if (resp.data !== undefined && resp.data !== '' && resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                dispatch(fetchCreditCardSuccessError(resp.data))
            } else {
                //dispatch(fetchCreditCardSuccess(resp.data));
                placeOrder(requestOptions, dispatch);
            }
        } catch (err) {
            placeOrder(requestOptions, dispatch);
        }
    }
}
async function updateCard(requestOptions, dispatch,requestBody) {
    dispatch(fetchPlaceOrderRequest())
        try {
            let resp = await axios.post(process.env.REACT_APP_PYW_PAYMENT_UPDATE_URL + "?ftoken=" + cookie.load("forterToken"), requestBody, requestOptions)
            if (resp.data !== undefined && resp.data !== '' && resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                dispatch(fetchCreditCardSuccessError(resp.data))
            } else {
                //dispatch(fetchCreditCardSuccess(resp.data));
                placeOrder(requestOptions, dispatch);
            }
        } catch (err) {
            placeOrder(requestOptions, dispatch);
        }
}

async function placeOrder(requestOptions, dispatch) {
    dispatch(fetchPlaceOrderRequest())
    requestOptions.headers.refid = sessionStorage.getItem("pywrefid");
    // requestOptions.headers.roToken = cookie.load("roToken");
    requestOptions.headers.userAgent = rdd.getUA;
    requestOptions.headers.osName = rdd.osName;
    requestOptions.headers.browserName = rdd.browserName;
    requestOptions.headers.deviceType = rdd.deviceType;
    requestOptions.headers.isBrowser = rdd.isBrowser;
    requestOptions.headers.isMobile = rdd.isMobile;
    requestOptions.headers.deviceId = duui.DeviceUUID().get();

    try {
        let resp = await axios.get(process.env.REACT_APP_PYW_PLACE_ORDER_URL + "?ftoken=" + cookie.load("forterToken"), requestOptions);

        if (resp.data !== undefined && resp.data !== '' && resp.data.errors !== undefined && resp.data.errors.length !== 0) {
            dispatch(fetchPlaceOrderSuccessError(resp.data));
        } else {
            //dispatch(fetchPlaceOrderSuccess(resp.data));
            OrderConfirmation(requestOptions, dispatch);
        }
    }
    catch (err) {
        OrderConfirmation(requestOptions, dispatch);
    }
}

async function OrderConfirmation(requestOptions, dispatch) {
    // sessionStorage.removeItem("pywrefid");
    let headers = {
        Accept: "Application/json",
        "Content-Type": "application/json",
        channel: "ONLINE",
        platform: "PYW",
        isLight:"Y"
    };
    headers.cartId = requestOptions.headers.cartId;
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");
    headers.refid = requestOptions.headers.refid

    const requestHeaders = {
        headers
    };
    try {
        let resp = await axios.get(process.env.REACT_APP_PYW_ORDER_CONFIRMATION + "?ftoken=" + cookie.load("forterToken"), requestHeaders)
        if (resp.data !== undefined && resp.data !== '' && resp.data.errors !== undefined && resp.data.errors.length !== 0) {
            dispatch(fetchOrderConfirmationSuccessError(resp.data));
        }
        else {
            dispatch(orderConfirmationSuccess(resp.data));
        }
    }
    catch (err) {
        window.close();
    }

}

export const fetchPlaceOrderRequest = () => {
    return {
        type: FETCH_PLACEORDER_REQUEST
    }
}

export const fetchPlaceOrderSuccess = apiData => {
    return {
        type: FETCH_PLACEORDER_SUCCESS,
        payload: apiData
    }
}
export const fetchPlaceOrderSuccessError = apiData => {
    return {
        type: FETCH_PLACEORDER_SUCCESS_ERROR,
        payload: apiData
    }
}
export const fetchPlaceOrderFailure = error => {
    return {
        type: FETCH_PLACEORDER_FAILURE,
        payload: error
    }
}

//placeOrder API Call End

//CreditCard API Call Start
export const fetchCreditCardSuccess = apiData => {
    return {
        type: FETCH_CREDITCARD_SUCCESS,
        payload: apiData
    }
}
export const fetchCreditCardSuccessError = apiData => {
    return {
        type: FETCH_CREDITCARD_SUCCESS_ERROR,
        payload: apiData
    }
}

export const fetchCreditCardFailure = error => {
    return {
        type: FETCH_CREDITCARD_FAILURE,
        payload: error
    }
}

//CreditCard API Call End

//OrderConfirmation Api Call Start
export const orderConfirmationDetails = (requestBody, requestHeaders, refid) => {
    return (dispatch) => {
        dispatch(orderConfirmationRequest())
        axios.get(process.env.REACT_APP_PYW_ORDER_CONFIRMATION + "?ftoken=" + cookie.load("forterToken"),
            requestHeaders
        ).then(resp => {
            if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                dispatch(fetchOrderConfirmationSuccessError(resp.data));
            }
            else {
                if(requestHeaders.headers.isLight == 'Y'){
                    dispatch(orderConfirmationLightSuccess(resp.data))
                }else{
                    dispatch(orderConfirmationSuccess(resp.data))
                }
            }
        })
            .catch(err => {
                // dispatch(orderConfirmationfailure(err.message))
            });
    }
}
export const orderConfirmationRequest = () => {
    return {
        type: ORDER_CONFIRMATION_REQUEST
    }
}
export const orderConfirmationSuccess = apiData => {
    return {
        type: ORDER_CONFIRMATION_SUCCESS,
        payload: apiData
    }
}
export const orderConfirmationLightSuccess = apiData => {
    return {
        type: ORDER_CONFIRMATION_LIGHT_SUCCESS,
        payload: apiData
    }
}
export const fetchOrderConfirmationSuccessError = apiData => {
    return {
        type: FETCH_ORDERCONFIRMATION_SUCCESS_ERROR,
        payload: apiData
    }
}
export const orderConfirmationfailure = error => {
    return {
        type: ORDER_CONFIRMATION_FAILURE,
        payload: error
    }
}
//OrderConfirmation Api Call END

//add subscription API Call Start

export const addSubscription = (requestOptions, requestBody, requestSubscription) => {
    return (dispatch) => {
        dispatch(addSubscriptionRequest())
        
        const headers = {
            Accept : "Application/json",
            "Content-Type": "application/json",
            platform :"PYW"
        };
        headers.cartId = sessionStorage.getItem("pywcartId");
        headers.merchantClientId = sessionStorage.getItem("pywmerchant");
        headers.refid = sessionStorage.getItem("pywrefid");
        const requestHeadeSubscription = {
        headers
        };

        axios.post(process.env.REACT_APP_PYW_ADD_SUBSCRIPTION_URL + "?ftoken=" + cookie.load("forterToken"),
            requestSubscription,
            requestHeadeSubscription).then(resp => {
                if (resp.data !== undefined && resp.data !== '' && resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                    dispatch(addSubscriptionSuccessError(resp.data))
                } else {
                    //dispatch(addSubscriptionSuccess(resp.data))
                    updateCard(requestOptions, dispatch,requestBody)
                }
            })
            .catch(err => {
                //updateCard(requestOptions, dispatch,requestBody)
                OrderConfirmation(requestOptions, dispatch);
                //var data = { "status": "error", "errors": [{ "type": "Apply_Points", "code": "05", "message": "Apply points service is currently unavailable" }] };
                //dispatch(addSubscriptionError(data))
            });
    }
}


export const addSubscriptionRequest = () => {
    return {
        type: ADD_SUBSCRIPTION_REQUEST
    }
}

export const addSubscriptionSuccess = apiData => {
    return {
        type: ADD_SUBSCRIPTION_SUCCESS,
        payload: apiData
    }
}
export const addSubscriptionSuccessError = apiData => {
    return {
        type: ADD_SUBSCRIPTION_SUCCESS_ERROR,
        payload: apiData
    }
}
export const addSubscriptionFailure = error => {
    return {
        type: ADD_SUBSCRIPTION_FAILURE,
        payload: error
    }
}
//add subscription API Call End


export const generatePaymentId = (requestBody, requestHeaders) => {

    return async (dispatch) => {
        dispatch(generatePaymentIdRequest())
        axios.post(process.env.REACT_APP_PYW_GEN_PAYMENTID_URL+ "?ftoken=" +  cookie.load("forterToken"),
            requestBody, requestHeaders
        ).then(resp => {
            if (resp.data !== undefined && resp.data !== '' && resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                dispatch(generatePaymentIdfailure(resp.data));
            }else {
                if (resp.data !== undefined) {
			        //cookie.save('pywid', resp.data.pywid,{ maxAge:parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), path:'/' });
		        }
                dispatch(generatePaymentIdSuccess(resp.data));
            }
        })
            .catch(err => {
                dispatch(generatePaymentIdfailure(err.message))
            });
    }
}
export const generatePaymentIdRequest = () => {
    return {
        type: GENERATE_PAYMENT_REQUEST
    }
}
export const generatePaymentIdSuccess = apiData => {
    return {
        type: GENERATE_PAYMENT_SUCCESS,
        payload: apiData
    }
}
export const generatePaymentIdfailure = error => {
    return {
        type: GENERATE_PAYMENT_FAILURE,
        payload: error
    }
}

export const getSdkContents = (requestBody,requestHeaders) => {
    return (dispatch) => {
        dispatch(getSdkContentsRequest())
        axios.post(process.env.REACT_APP_PYW_GET_SDK_OFFER_URL+ "?ftoken=" + cookie.load("forterToken"),
          requestBody , requestHeaders 
        ).then(resp => {
            if (resp.data !== undefined && resp.data !== ''  && resp.data.CMP_PayYourWay !== undefined && resp.data.CMP_PayYourWay !== '') {
                dispatch(getSdkContentsSuccess(resp.data))
            }else {                 
                dispatch(getSdkContentsSuccessError(resp.data))
            }})
            .catch(err => {
                dispatch(getSdkContentsfailure(err))
            });
    }
}
export const getSdkContentsRequest = () => {
    return {
        type: GET_SDKCONTENT_REQUEST
    }
}
export const getSdkContentsSuccess = apiData => {
    return {
        type: GET_SDKCONTENT_SUCCESS,
        payload: apiData
    }
}

export const getSdkContentsSuccessError = apiData => {
    return {
        type: GET_SDKCONTENT_SUCCESS_ERROR,
        payload: apiData
    }
}
export const getSdkContentsfailure = error => {
    return {
        type: GET_SDKCONTENT_FAILURE,
        payload: error
    }
}

export const validateEmail = (requestBody , requestHeaders) =>{
    return async(dispatch) => {
        dispatch(validateEmailRequest())
        //requestHeaders.headers.forterToken=cookie.load("forterToken");
        await axios.post(process.env.REACT_APP_VALIDATE_EMAIL_URL + "?ftoken=" + cookie.load("forterToken"),
            requestBody, requestHeaders
        ).then(resp => {
            if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                dispatch(validateEmailSuccessError(resp.data))
            }else{
                dispatch(validateEmailSuccess(resp.data))
            }
          })
          .catch(err => {
            dispatch(validateEmailError(err))
          });
    }
}

export const validateEmailRequest = () => {
    return {
        type: VALIDATE_EMAIL_REQUEST
    }
}
export const validateEmailSuccess = apiData => {
    return {
        type: VALIDATE_EMAIL_SUCCESS,
        payload: apiData
    }
}

export const validateEmailSuccessError = apiData => {
    return {
        type: VALIDATE_EMAIL_SUCCESS_ERROR,
        payload: apiData
    }
}
export const validateEmailError = error => {
    return {
        type: VALIDATE_EMAIL_FAILURE,
        payload: error
    }
}

export const disableButtonClick = () => {
    return {
        type: DISABLE_BUTTON_CLICK_TRUE,
    }
}
export const enableButtonClick = () => {
    return {
        type: ENABLE_BUTTON_CLICK_TRUE,
    }
}

// Verify Email API start

export const sendVerifyEmail = (requestBody, requestHeaders) => {

    return async (dispatch) => {
        dispatch(verifyEmailRequest())
         try {
            const resp = await axios.post(process.env.REACT_APP_SEND_RESET_PASSWORD_URL + "?ftoken=" + cookie.load("forterToken"),
            requestBody, requestHeaders)
            if (resp.data.status === SUCCESS || resp.data.statusCode === CODE_200) {
                dispatch(verifyEmailSuccess(resp.data))
            }
            else {
                dispatch(verifyEmailSuccessFailure(resp.data))
            }            
         } catch (err) {
            var data = { "status": "error", "errors": [{ "type": "Email_Verification", "code": "04", "message": "Unable to send a mail" }] };
            dispatch(verifyEmailfailure(data))
         }
    }
}

export const verifyEmailRequest = () => {
    return {
        type: VERIFY_EMAIL_REQUEST
    }
}
export const verifyEmailSuccess = apiData => {
    return {
        type: VERIFY_EMAIL_SUCCESS,
        payload: apiData
    }
}
export const verifyEmailfailure = error => {
    return {
        type: VERIFY_EMAIL_FAILURE,
        payload: error
    }
}
export const verifyEmailSuccessFailure = error => {
    return {
        type:VERIFY_EMAIL_SUCCESS_ERROR,
        payload: error
    }
}

export const enableInputs = () => {
    return {
        type:ENABLE_INPUTS,
    }
}
export const disableInputs = (btn) => {
    return {
        payload:btn,
        type:DISABLE_INPUTS,
    }
}
export const fetchMerchantDetails = (requestBody, requestHeaders) => {
    return (dispatch) => {
        dispatch(fetchMerchantDetailsRequest());
        axios.get(process.env.REACT_APP_MERCHANT_DETAILS_URL, requestHeaders, requestBody
        ).then(resp => {
            if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                dispatch(fetchMerchantDetailsSuccessError(resp.data));
            }
            else {
                sessionStorage.setItem('merchantName', resp.data.merchantDetails.merchantName);
                sessionStorage.setItem('merchantHeadline', resp.data.merchantDetails.merchantHeadline);
                dispatch(fetchMerchantDetailsSuccess(resp.data));
            }
        }).catch(err => {
            dispatch(fetchMerchantDetailsFailure(err.message));
        });
    }
}

export const fetchMerchantDetailsRequest = () => {
    return {
        type: FETCH_MERCHANT_DETAIL_REQUEST
    }
}

export const fetchMerchantDetailsSuccessError = apiData => {
    return {
        type: FETCH_MERCHANT_DETAIL_SUCCESS_ERROR,
        payload: apiData
    }
}

export const fetchMerchantDetailsSuccess = apiData => {
    return {
        type: FETCH_MERCHANT_DETAIL_SUCCESS,
        payload: apiData
    }
}

export const fetchMerchantDetailsFailure = error => {
    return {
        type: FETCH_MERCHANT_DETAIL_FAILURE,
        payload: error
    }
}

export const fetchCreateMember = (requestBody, requestHeaders) => {

    return async (dispatch) => {
        dispatch(createMemberRequest())
         try {
            const resp = await axios.post(process.env.REACT_APP_CREATE_MEMBER_URL + "?ftoken=" + cookie.load("forterToken"), requestBody, requestHeaders)
            if (resp.data.status === SUCCESS || resp.data.statusCode === CODE_200) {
                dispatch(createMemberSuccess(resp.data))
            }
            else {
                dispatch(createMemberSuccessFailure(resp.data))
            }            
         } catch (err) {
            dispatch(createMemberfailure(err))
         }
    }
}

export const createMemberRequest = () => {
    return {
        type: CREATE_MEMBER_REQUEST,
    }
}
export const createMemberSuccess = (apiData) => {
    return {
        type: CREATE_MEMBER_SUCCESS,
        payload: apiData
    }
}
export const createMemberfailure = (error) => {
    return {
        type: CREATE_MEMBER_FAILURE,
        payload: error
    }
}
export const createMemberSuccessFailure = (error) => {
    return {
        type: CREATE_MEMBER_SUCCESS_ERROR,
        payload: error
    }
}

export const activateMember = (requestBody, requestHeaders) => {
    return (dispatch) => {
        dispatch(activateMemberRequest());
        axios.post(process.env.REACT_APP_ACTIVATE_MEMBER_URL + "?ftoken=" + cookie.load("forterToken"), requestBody,requestHeaders
        ).then(resp => {
            if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                dispatch(activateMemberSuccessError(resp.data));
            }
            else {
                dispatch(activateMemberSuccess(resp.data));
            }
        }).catch(err => {
            dispatch(activateMemberFailure(err.message));
        });
    }
}

export const activateMemberRequest = () => {
    return {
        type: ACTIVATE_MEMBER_REQUEST,
    }
}

export const activateMemberSuccessError = apiData => {
    return {
        type: ACTIVATE_MEMBER_SUCCESS_ERROR,
        payload: apiData
    }
}

export const activateMemberSuccess = apiData => {
    return {
        type: ACTIVATE_MEMBER_SUCCESS,
        payload: apiData
    }
}

export const activateMemberFailure = error => {
    return {
        type: ACTIVATE_MEMBER_FAILURE,
        payload: error
    }
}