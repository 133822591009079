import cookie from "react-cookies";
import { fetchMerchantDetails } from "../redux";
export function handleChange(obj) { 
    
    if (obj.target === document.getElementById("openModal")) {
        const backdrop = document.querySelector(".backdrop");
        document.getElementById('iframeSeeDetails').src =process.env.REACT_APP_CITI_CARD_SEE_DETAILS_URL+"intcmp="+sessionStorage.getItem("intcmpID")+"&p=PYW#terms";
        backdrop.classList.add("show");
        return;
    }

    const card = obj.currentTarget;
    const checkbox = obj.currentTarget.querySelector("input[type='checkbox']");
    const alert = document.querySelector("#alert");
    const cvv = document.querySelector("#cvv");
    const expiry = document.querySelector("#expiry");
    const payButton = document.querySelector("#payButton");
    const {checked} = checkbox;
    checkbox.checked = !checked;
    if (!checked) {
        card.classList.add("selected")
        alert && alert.classList.remove("show")
        payButton && (payButton.removeAttribute("disabled"))
        cvv && (cvv.disabled = false)
        expiry && (expiry.disabled = false)
    } else {
        card.classList.remove("selected")
        alert && alert.classList.add("show")
        payButton && (payButton.setAttribute("disabled",""))
        cvv && (cvv.disabled = true)
        expiry && (expiry.disabled = true)
        cvv.value=""
    }
}

export function hideBackdrop(obj) {
    const backdrop = document.querySelector(".backdrop");
    document.getElementById('iframeSeeDetails').src ="";
    backdrop.classList.remove("show");
    return;
}
export function hideInfoBackdrop(obj) {
    const backdrop = document.querySelector(".infobackdrop");
    document.getElementById('iframeInfo').src ="";
    backdrop.classList.remove("show");
    return;
}
export function hideInfoShowCitiBackdrop(obj) {
    const backdrop = document.querySelector(".infobackdrop");
    backdrop.classList.remove("show");
    const citbackdrop = document.querySelector(".citibackdrop");
    citbackdrop.classList.add("show");
    
    return;
}
export function showInfoBackdrop(obj) {
    const backdrop = document.querySelector(".infobackdrop");
    document.getElementById('iframeInfo').src =process.env.REACT_APP_CITI_CARD_INFO_URL+"&intcmp="+cookie.load("intcmpID");
    backdrop.classList.add("show");
    return;
}
export function handleBackdrop(obj) {
    const backdrop = document.querySelector(".backdrop");
    backdrop.classList.add("show");
    return;
}
export function hideTermShowCitiBackdrop(obj) {
    const backdrop = document.querySelector(".backdrop");
    backdrop.classList.remove("show");
    const termbackdrop = document.querySelector(".citibackdrop");
    termbackdrop.classList.add("show");
    
    return;
}
export function handleClickBanner(obj) {
    const banner = document.querySelector(".banner");
    banner.classList.add("hide");
    return;
}

export function handleClickErrorBanner(obj) {
    const banner = document.querySelector(".error-banner");
    banner.classList.add("hide");
    return;
}
export function handleClickSuccessBanner(obj) {
    const banner = document.querySelector(".success-banner");
    banner.classList.add("hide");
    return;
}
export function parseDollarValue(obj) {
    let value = obj
    if (value != undefined  ) {
        if(value.indexOf(',') != -1)
        return parseFloat(value.split(',').join('')) / 1000;
        else
        return value/1000;
    } else
        return 0;
}

export function parseDollarValuetoPoints(obj) {
    let value = obj
    if (value != undefined && value!= 0) {
        return value*1000;
    } else
        return 0;
}

export function parseCardValue(obj) {
    let value = obj
    if (value != undefined && value.indexOf('*') != -1) {
        return value.split('*').join('');
    } else
        return value;
}
export function handleCitiBackdrop(obj) {
    const backdrop = document.getElementById("citibackdrop");
    backdrop.classList.add("show");
    return;
}
export function hideCitiBackdrop(obj) {
    if(document.getElementById("citibackdrop")) {
        const backdrop = document.getElementById("citibackdrop");
        backdrop.classList.remove("show");
    }
    return;
}

// export function handleEmailVerificationBackdrop(obj) {
//     const backdrop = document.getElementById("emailbackdrop");
//     backdrop.classList.add("show");
//     return;
// }

export function hideEmailVerificationBackdrop(obj) {
    if(document.getElementById("emailbackdrop")) {
        const backdrop = document.getElementById("emailbackdrop");
        backdrop.classList.remove("show");
    }
    return;
}

export function showTermBackdrop(obj , url) {
    const backdrop = document.querySelector(".termbackdrop");
    //POPUP_FLAGS.TERM_POPUP_FLAG=
    document.getElementById('iframeTerm').src =url+"?intcmp="+sessionStorage.getItem("intcmpID");
    backdrop.classList.add("show");
    return;
}
export function hideTermBackdrop(obj) {
    const backdrop = document.querySelector(".termbackdrop");
    document.getElementById('iframeTerm').src ="";
    backdrop.classList.remove("show");
    return;
}

export function showPrivacyBackdrop(obj , url) {
    const backdrop = document.querySelector(".privacybackdrop");
     document.getElementById('iframePrivacy').src =url+"?intcmp="+sessionStorage.getItem("intcmpID")
    backdrop.classList.add("show");
    return;
}
export function hidePrivacyBackdrop(obj) {
    const backdrop = document.querySelector(".privacybackdrop");
     document.getElementById('iframePrivacy').src ="";
    backdrop.classList.remove("show");
    return;
}

export function handleModalPopup(obj, url) {
    if (obj.currentTarget === document.getElementById("openModal")) {
        document.getElementById("waitText").style.visibility = "visible";
        setTimeout(() => {document.getElementById("waitText").style.visibility = "hidden";}, process.env.REACT_APP_HS_WAIT_TEXT_SEE_DETAILS_DURATION);
        const backdrop = document.querySelector(".backdrop");
        document.getElementById('iframeSeeDetails').src = url;
        backdrop.classList.add("show");
        return;
    }
}

export function closeWindow() {
    window.close();
}

const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function generateString(length) {
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function showHSDeclineBackdrop(obj) {
    const backdrop = document.querySelector(".declinebackdrop");
    const container = document.querySelector(".main-container");
    container.style.overflow="hidden"
    backdrop.classList.add("show");
    return;
}
export function hideHSDeclineBackdrop(obj) {
    const backdrop = document.querySelector(".declinebackdrop");
    const container = document.querySelector(".main-container");
    container.style.overflow=""
    backdrop.classList.remove("show");
    return;
}

export const validateGuestEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email)
}

export const  getMerchantDetails = async (dispatch)=>{
    const headers = {
        Accept: "Application/json",
        "Content-Type": "application/json",
        platform: "PYW",
        merchantclientid: sessionStorage.getItem("pywmerchant"),
    };
    if(sessionStorage.getItem('pywrefid')){
        headers.refid = sessionStorage.getItem('pywrefid')
    }
    let requestBody = {};
    const requestHeaders = {
        headers
    };
   await dispatch(fetchMerchantDetails(requestBody, requestHeaders));
}