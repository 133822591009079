import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cookie from "react-cookies";
import {headers,PYWCONFIRMATION} from "../../pywConstantComponent/PywConstant";
import { orderConfirmationDetails } from "../../redux";
import PywError from"../../pywErrorComponent/PywError";
import Pyw_PaymentConfirm from"./Pyw_PaymentConfirm";
import Pyw_PaymentFailed from"./Pyw_PaymentFailed";
import Pyw_10112 from"./Pyw_10112";
import { loading } from "../../pywConstantComponent/PywDesignsConstant";
import * as duui from'device-uuid'
import { fetchWebSocketDetails } from "../../redux/pywWebSocketDetails/pywWebSocketActions";
import { WS_PARAMS } from "../../pywConstantComponent/pywWebSocketConstant";
import Pyw_PaymentOnHold from "./Pyw_PaymentOnHold";
import { getMerchantDetails } from "../../pywUtilComponent/PywUtil";
import Pyw_UniversalPaymentConfirm from "./Pyw_UniversalPaymentConfirm";
const Pyw_OrderConfirmation_ws = (props) => {

    //const { user, isAuthenticated, isLoading } = useAuth0();
    //const pageData = useSelector(state => state.pageData);
    const [cartId, setCartId] = useState("");
    const [refID, setrefID] = useState("");
    const [status, setStatus] = useState("");
    const dispatch = useDispatch();
    const [navigate, setNavigate] = useState(false);
    const [intervalId, setIntervalId] = useState("");
    const [durationId, setDurationId] = useState("");
    const [confirmFlag, setConfirmFlag] = useState(false);
    const pageData = useSelector(state => state.pageData.orderConfirmationResponseCode ? state.pageData:state.wsPageData);
    const [ocflag, setOcflag] = useState("false");

    const addStatusOnUrl = (statusType) => {
        window.location.replace(window.location.href + "&status=" + statusType);
    };

    useEffect(async() => {
        const queryString = require("query-string");
        const values = queryString.parse(props.location.search);
        const cartId = values.pywid;
        const refId = values.pywrefid;
        sessionStorage.setItem("pywrefid",values.pywrefid)
        // const refId = window.opener === null ? encodeURIComponent(values.pywrefid) : values.pywrefid;;
        const merchantClientId = values.merchant
        sessionStorage.setItem("pywmerchant",merchantClientId)
        const statusType = values.status;

        setStatus(statusType);
        setCartId(cartId);
        setrefID(refId)
        let requestBody = {
        };

        headers.cartId=cartId;
		headers.merchantClientId = merchantClientId;
        headers.refid = refId;
        // headers.pywstate = PYWCONFIRMATION+"~~"+cookie.load("at0pyw")+duui.DeviceUUID().get();
        //const cartId = headers.cartId;
        const requestHeaders = {
            headers
        };
        if(statusType === undefined){
           // WS_PARAMS.listenerUrl = WS_PARAMS.listenerUrl.concat(cartId);
           WS_PARAMS.url = process.env.REACT_APP_SOCKET_URL+"?s=pyw&id="+cartId
           WS_PARAMS.timeout= process.env.REACT_APP_WS_ORDER_CONFIRMATION_TIMEOUT
           WS_PARAMS.timeoutRequire= true

            dispatch(fetchWebSocketDetails(WS_PARAMS));

        }else{
            dispatch(orderConfirmationDetails(requestBody, requestHeaders, ""));
        }

        await getMerchantDetails(dispatch);
        
        }, []);

    if (navigate) {
        window.close();
    }
    else if (pageData.apiError || pageData.iscolse ) {
        return <Pyw_10112 props={props}/>;
    }
    else if (pageData.pageLoading || pageData.merchantDetailsLoading) {
       return (loading());
    }
    else if (pageData.confirmStatus!==undefined &&pageData.confirmStatus!==""&&pageData.confirmStatus==="confirm") {
        if(pageData?.merchantDetailResponse?.merchantDetails
            ?.successPath===undefined ||  pageData?.merchantDetailResponse?.merchantDetails
            ?.successPath==="" || pageData?.merchantDetailResponse?.merchantDetails
            ?.returnPath === pageData?.merchantDetailResponse?.merchantDetails
            ?.successPath) {            
            if ((status === undefined || status === "")) {
                addStatusOnUrl("confirm")
            } else {
                return (
                    <Pyw_PaymentConfirm pageData={pageData}/>
                    // <Pyw_UniversalPaymentConfirm/>
                )
            }
        }
        else {
            window.location.replace(pageData?.merchantDetailResponse?.merchantDetails
                ?.successPath + "?pywid=" + cartId + "&pywrefid=" + refID  + "&pywmsg=Y");
        }
    } else if (pageData.confirmStatus!==undefined &&pageData.confirmStatus!==""&& pageData.confirmStatus==="interim-order") { 
        if ((status === undefined || status === "")) {
            addStatusOnUrl("onhold")
        }else{
        return ( <Pyw_PaymentOnHold pageData={pageData}/>)
    }
    }else if (pageData.confirmStatus!==undefined &&pageData.confirmStatus!==""&& pageData.confirmStatus==="cart") { 
        if ((status === undefined || status === "")) {
            addStatusOnUrl("failed")
        }else{
        return ( <Pyw_PaymentFailed pageData={pageData}/>)
        }
    }else if (pageData.confirmStatus!==undefined &&pageData.confirmStatus!==""&& pageData.confirmStatus==="cancel") { 
        if ((status === undefined || status === "")) {
            addStatusOnUrl("failed")
        }else{
        return ( <Pyw_PaymentFailed pageData={pageData}/>)
        }
    }else { 
       return <div/>;
    
    }
}
export default Pyw_OrderConfirmation_ws;