import React, { useState, useEffect } from "react";
import "./PywSummary.styles.scss";
import { images } from "../../pywImagesComponent/PywImages";
// import { Redirect } from "react-router-dom";
import {
  parseDollarValuetoPoints,
  parseDollarValue,
  getMerchantDetails,
} from "../../pywUtilComponent/PywUtil";
import {
  PYWCUSTOMSUMMARY,
  PYW_CONFIRMATION,
  PYW_CUSTOM_SUMMARY,
  PYW_MEMBER,
  PYW_SUMMARY,
  SUBSCRIPTION,
  TRUE,
} from "../../pywConstantComponent/PywConstant";
import { CUSTOMCC } from "../../pywConstantComponent/PywSummaryMessageConstant";
import cookie from "react-cookies";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPrepareDetails,
  fetchPlaceOrder,
  fetchPlaceOrderAndUpdateCard,
  addSubscription,
  fetchCreateMember,
} from "../../redux";
import Payment from "payment";
import PywError from "../../pywErrorComponent/PywError";
import * as duui from "device-uuid";
import { useAuth0 } from "@auth0/auth0-react";
import postscribe from "postscribe";
import {
  headerBars,
  headerPyw,
  headerUserWithPoints,
  validatePayment,
  loading,
} from "../../pywConstantComponent/PywDesignsConstant";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatNumber,
} from "../../pywUtilComponent/PywCardUtil";
import PywTealiumAnalytics from "../../pywAnalytics/PywTealiumAnalytics";
import {
  GuestHeaderBar,
  GuestHeaderBarCustomSummary,
  headerBar,
} from "../../pywConstantComponent/PywGuestDesignConstant";
import { Redirect } from "../../pywUtilComponent/PywRedirect";
import AnalyticsWrapper from "../../pywAnalytics/AnalyticsWrapper";
import { GTMAnalytics } from "../../pywAnalytics/pywGTMAnalytics/Pyw_GTM_Analytics";
const PywGuestCustomSummary = () => {
  const { user, isAuthenticated } = useAuth0();
  const [userInfo, setUserInfo] = useState("");
  const pageData = useSelector((state) => state.pageData);
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardError, setCardError] = useState("");
  const [expiry, setExpiry] = useState("");
  const [expiryError, setExpiryError] = useState("");
  const [cvv, setCvv] = useState("");
  const [cvvError, setCvvError] = useState("");
  const [name, setName] = useState("");
  const [setNameError] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [zipcodeError, setZipcodeError] = useState("");
  const [memberFlag, setMemberFlag] = useState(false);
  const [nameParts, setNameParts] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
  });
  const mapNameParts = (fullName) => {
    const nameArray = fullName.trim().split(" ");
    const nameObject = {
      firstname: nameArray.length === 1
        ? nameArray[0]
        : nameArray.length === 2
        ? nameArray[0]
        : nameArray.slice(0, -2).join(" "),
      middlename: nameArray.length > 2
        ? nameArray[nameArray.length - 2]
        : "",
      lastname: nameArray.length >= 1
        ? nameArray[nameArray.length - 1]
        : "",
    };
    return nameObject;
  };
  
  const placeOrderHandller = () => {
    const headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform: "PYW",
    };
    headers.cartId = sessionStorage.getItem("pywcartId");
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");
    headers.refid = sessionStorage.getItem("pywrefid");
    const requestOptions = {
      headers,
    };

    if (
      !pageData.tenderTypes.includes("CC_DC") ||
      (parseFloat(pageData.summary.pointsDollarValue) ===
        parseFloat(pageData.summary.priceSubTotal) &&
        (pageData.addlAttrs?.payType === undefined ||
          !pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION)))
    ) {
      dispatch(fetchPlaceOrder(requestOptions));
    } else if (
      parseFloat(pageData.summary.pointsDollarValue) !==
        parseFloat(pageData.summary.priceSubTotal) ||
      (pageData.addlAttrs?.payType !== undefined &&
        pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION))
    ) {
      const requestBody = {
        payment: [
          {
            paymentType: "creditCard",
            creditCard: {
              cardNumber: cardNumber,
              creditCardType: Payment.fns.cardType(cardNumber),
              cardLogoName: Payment.fns.cardType(cardNumber),
              expiryDate: expiry.replace("/", "20"),
              cvvNumber: cvv,
              cardHolderName: name,
              streetAddress: "USA",
              zipCode: zipcode,
              countryCode: "US",
              currency: "USD",
            },
          },
        ],
      };

      if (
        pageData.addlAttrs?.payType !== undefined &&
        pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION)
      ) {
        let itemId = "";
        {
          pageData.items.map((item) => {
            itemId = item.itemId;
          });
        }
        var requestSubscription = {
          subscriptions: [
            {
              itemId: itemId,
              subscription: {
                id: pageData.addlAttrs?.payType.replace(SUBSCRIPTION, ""),
              },
            },
          ],
        };

        dispatch(
          addSubscription(requestOptions, requestBody, requestSubscription)
        );
      } else {
        dispatch(fetchPlaceOrderAndUpdateCard(requestOptions, requestBody));
      }

      //dispatch(fetchPlaceOrderAndUpdateCard(requestOptions, requestBody));
    }
  };
  const handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
      setCardNumber(target.value);
      if (Payment.fns.validateCardNumber(target.value)) {
        setCardError("");
      } else {
        setCardError("Enter valid card");
      }
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      setExpiry(target.value);
      if (!Payment.fns.validateCardExpiry(target.value)) {
        setExpiryError("Enter the valid expiry date");
      } else {
        setExpiryError("");
      }
    } else if (target.name === "cvv") {
      target.value = formatCVC(target.value, cardNumber);
      setCvv(target.value);
      if (cardNumber === "") {
        setCvvError("Enter the card number first");
      } else {
        if (
          !Payment.fns.validateCardCVC(
            target.value,
            Payment.fns.cardType(cardNumber)
          )
        ) {
          setCvvError("Enter valid CVV");
          if (!Payment.fns.validateCardNumber(cardNumber)) {
            setCvvError("Enter valid CVV");
          }
        } else if (
          Payment.fns.validateCardCVC(
            target.value,
            Payment.fns.cardType(cardNumber)
          )
        ) {
          if (!Payment.fns.validateCardNumber(cardNumber)) {
            setCvvError("");
            setCardError("Enter valid card");
          } else {
            setCvvError("");
          }
        }
      }
    } else if (target.name === "name") {
      setName(target.value);
      if (target.value !== "") {
        setNameError("");
      } else {
        setNameError("Enter valid name");
      }
    } else if (target.name === "zipcode") {
      target.value = formatNumber(target.value);
      setZipcode(target.value);
      if (target.value !== "" && target.value.length == 5) {
        setZipcodeError("");
      } else {
        setZipcodeError("Enter valid zipcode");
      }
    }
  };
  const [summary, setSummary] = useState(false);
  useEffect(async () => {
    const refid = sessionStorage.getItem("pywrefid");
    const items = [];
    let requestBody = { items };
    const headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform: "PYW",
    };
    headers.cartId = sessionStorage.getItem("pywcartId");
    headers.refid = sessionStorage.getItem("pywrefid");
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");

    headers.pywstate =
      PYWCUSTOMSUMMARY +
      "~~" +
      sessionStorage.getItem("at0pyw") +
      duui.DeviceUUID().get();
    const requestHeaders = {
      headers,
    };
    dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));
    await getMerchantDetails(dispatch);
  }, [isAuthenticated]);

  useEffect(() => {
    if (
      pageData.orderConfirmationResponseCode !== undefined &&
      pageData.orderConfirmationResponseCode !== "" &&
      process.env.REACT_APP_GTM_ANALYTICS === TRUE
    ) {
      var cartid = sessionStorage.getItem("pywcartId");
      var refid = sessionStorage.getItem("pywrefid");
      var merchant = sessionStorage.getItem("pywmerchant");

      GTMAnalytics("page", "place_order", {
        ...pageData,
        checkoutStep: 3,
        cartId: cartid,
        merchantName: merchant,
        merchant_transaction_id: pageData?.txnId,
        ...pageData.responseData,
      });
    }
    if (pageData?.createMemberSuccess && pageData?.createMemberResponse) {
      placeOrderHandller();
    }
  }, [pageData]);

  useEffect(() => {
    if (pageData.isValid) {
      GTMAnalytics("page", "checkout", {
        ...pageData,
        checkoutStep: 3,
      });
    }
  }, [pageData.isValid]);

  const createMember = async () => {
    const nameObject = mapNameParts(name);
    const headers = {
      "Content-Type": "application/json",
      platform: "PYW",
    };
    headers.cartId = sessionStorage.getItem("pywcartId");
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");
    headers.refid = sessionStorage.getItem("pywrefid");
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      firstName: nameObject.firstname,
      middleName: nameObject.middlename,
      lastName: nameObject.lastname,
      zipCode: zipcode,
      emailAddress: pageData?.addlAttrs?.params?.useremail,
    };
    dispatch(fetchCreateMember(requestBody, requestHeaders));
  };

  return (
    <main
      id="rtockenpost"
      className="ff-open-sans flex-container custom-summary-wrapper"
    >
      {(() => {
        if (
          pageData.orderConfirmationResponseCode !== undefined &&
          pageData.orderConfirmationResponseCode !== ""
        ) {
          var cartid = sessionStorage.getItem("pywcartId");
          var refid = sessionStorage.getItem("pywrefid");
          var merchant = sessionStorage.getItem("pywmerchant");
          sessionStorage.removeItem("pywcartId");
          sessionStorage.removeItem("pywrefid");
          return (
            <div>
              {process.env.REACT_APP_TEALIUM_ANALYTICS === TRUE && (
                <PywTealiumAnalytics
                  response={pageData}
                  tealiumEvent="place_order"
                />
              )}

              {loading()}
              {(() => {
                if (
                  pageData.responseData.redirect !== undefined &&
                  pageData.responseData.redirect === "_self"
                ) {
                  if (pageData.confirmStatus === "interim-order") {
                    setTimeout(() => {
                      window.location.replace(
                        pageData?.responseData?.returnUrl +
                          "?pywid=" +
                          cartid +
                          "&pywrefid=" +
                          refid +
                          "&merchant=" +
                          merchant +
                          "&pywmsg=Y"
                      );
                    }, "2000");
                  } else {
                    setTimeout(() => {
                      window.location.replace(
                        pageData?.responseData?.returnUrl +
                          "?pywid=" +
                          cartid +
                          "&pywrefid=" +
                          refid +
                          "&merchant=" +
                          merchant +
                          "&pywmsg=N"
                      );
                    }, "2000");
                  }
                } else {
                  setTimeout(() => {
                    window.close();
                  }, "2000");
                }
              })()}
              {(() => {
                if (pageData.confirmStatus === "interim-order") {
                  return <Redirect to={PYW_CONFIRMATION} push={true} />;
                }
              })()}
            </div>
          );
        } else {
          if (pageData.pageError) {
            return (
              <PywError pageData={pageData} pageName={"guestCustomSummary"} />
            );
          } else if (pageData.validating) {
            return validatePayment(pageData.merchantHeadline);
          } else if (pageData.pageLoading || pageData.merchantDetailsLoading) {
            return loading();
          } else if (isAuthenticated) {
            return <Redirect to={PYW_CUSTOM_SUMMARY} push={true} />;
          } else if (pageData.isValid) {
            return (
              <div>
                {headerPyw(pageData.merchantHeadline)}
                {process.env.REACT_APP_TEALIUM_ANALYTICS === TRUE && (
                  <PywTealiumAnalytics
                    response={pageData}
                    tealiumEvent="checkout"
                  />
                )}
                <PywError pageData={pageData} pageName="guestCustomSummary" />
                <section>
                  {GuestHeaderBarCustomSummary(pageData)}
                  <div className="container-wrapper-without-padding">
                    <h4 className="mt-16 fs-16 fw-600 lh-24 title-grey">
                      Use a credit card to pay the remaining{" "}
                      <span className="semi-light-blue">
                        {pageData.summary.currency +
                          pageData.summary.paymentDue}
                      </span>{" "}
                      and complete your purchase.
                    </h4>
                    <div>
                      <div>
                        <div className="container-cc">
                          <figure className="cc">
                            <img src={images.visa} alt="" />
                          </figure>
                          <figure className="cc">
                            <img src={images.masterCard} alt="" />
                          </figure>
                          <figure className="cc">
                            <img src={images.discover} alt="" />
                          </figure>
                        </div>
                      </div>

                      <form>
                        <div className="mb-12">
                          <label className="input-label ">
                            <input
                              name="number"
                              onChange={handleInputChange}
                              className="input-text input-text-custom"
                              value={cardNumber}
                              type="text"
                              autocomplete="off"
                              required
                            />
                            <span className="label-span title-message-grey fw-400 fs-14 lh-16">
                              Card Number
                            </span>
                          </label>
                          <small className="orange alert show" id="alert">
                            {cardError}
                          </small>
                        </div>
                        <div className="mb-12">
                          <div className="date-cvv">
                            <label className="input-label ">
                              <input
                                name="expiry"
                                type="text"
                                value={expiry}
                                onChange={handleInputChange}
                                className="input-text half input-text-custom"
                                autocomplete="off"
                                required
                              />
                              <span className="label-span title-message-grey fw-400 fs-14 lh-16">
                                mm/yy
                              </span>
                            </label>
                            <label className="input-label ">
                              <input
                                name="cvv"
                                value={cvv}
                                type="text"
                                onChange={handleInputChange}
                                className="input-text half input-text-custom"
                                autocomplete="off"
                                required
                              />
                              <span className="label-span title-message-grey fw-400 fs-14 lh-16">
                                cvv
                              </span>
                            </label>
                          </div>
                          <div className="date-cvv">
                            <small
                              className="orange alert show half mb-2 mt-0"
                              id="alert"
                            >
                              {expiryError}
                            </small>
                            <small
                              className="orange alert show half mb-2 mt-0"
                              id="alert"
                            >
                              {cvvError}
                            </small>
                          </div>
                        </div>
                        <label className="input-label ">
                          <input
                            name="name"
                            value={name}
                            type="text"
                            onChange={handleInputChange}
                            className="input-text mb-12 input-text-custom"
                            onKeyPress={(e) => {
                              if (!/[a-zA-Z ]/.test(e.key)) {
                                e.preventDefault();
                              }
                              e.key === "Enter" && e.preventDefault();
                            }}
                            autocomplete="off"
                            required
                          />
                          <span className="label-span title-message-grey fw-400 fs-14 lh-16">
                            Name
                          </span>
                        </label>
                        <label className="input-label">
                          <input
                            name="zipcode"
                            type="text"
                            value={zipcode}
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onChange={handleInputChange}
                            className="input-text  input-text-custom mb-12"
                            maxLength={5}
                            autoComplete="off"
                            required
                          />
                          <span className="label-span title-message-grey fw-400 fs-14 ">
                            Zip Code
                          </span>
                          <small className="orange alert show" id="alert">
                            {zipcodeError}
                          </small>
                        </label>
                      </form>
                      {(() => {
                        if (
                          pageData?.tenderTypes?.includes("CC_DC") &&
                          pageData.summary.earnPointSummary !== undefined &&
                          parseInt(
                            pageData.summary.earnPointSummary.totalEarnPoints
                          ) > 0 &&
                          (pageData.summary.priceSubTotal >
                            parseDollarValue(
                              pageData?.sywPoints?.currentPointsBalance
                            ) ||
                            (pageData.addlAttrs?.payType !== undefined &&
                              pageData.addlAttrs?.payType.startsWith(
                                SUBSCRIPTION
                              )))
                        ) {
                          return (
                            <p className="dark-grey fs-14 earn-points-message fw-400 lh-20">
                              Earn{" "}
                              <span className="semi-light-blue">
                                {new Intl.NumberFormat("en-US").format(
                                  pageData.summary.earnPointSummary
                                    .totalEarnPoints
                                )}
                              </span>{" "}
                              Shop Your Way points with this purchase!
                            </p>
                          );
                        }
                      })()}
                    </div>
                    {!pageData?.tenderTypes?.includes("CC_DC") &&
                      pageData.summary.priceSubTotal >
                        parseDollarValue(
                          pageData?.sywPoints?.currentPointsBalance
                        ) && <div className="tender-body"></div>}
                  </div>
                </section>

                <footer className="container-wrapper-without-padding mb-62 pb-0 nocc-109-footer">
                  {(() => {
                    if (
                      parseFloat(pageData.summary.tenderAmountDue) !== 0 &&
                      pageData.summary.priceSubTotal <=
                        parseDollarValue(
                          pageData?.sywPoints?.currentPointsBalance
                        )
                    ) {
                      return (
                        <h5 className="orange insufficent-points fs-14 mt-20 mb-73 lh-20">
                          Insufficient points to make this purchase. Use your
                          Shop Your Way Points to complete the purchase.
                        </h5>
                      );
                    }
                  })()}

                  {(() => {
                    if (
                      (parseFloat(pageData.summary.tenderAmountDue) === 0 &&
                        (pageData.addlAttrs?.payType === undefined ||
                          !pageData.addlAttrs?.payType.startsWith(
                            SUBSCRIPTION
                          ))) ||
                      (name != "" &&
                        zipcode != "" &&
                        zipcode.length == 5 &&
                        cardError == "" &&
                        cvvError == "" &&
                        expiryError == "" &&
                        zipcodeError == "")
                    ) {
                      return (
                        <button
                          className="btn btn-primary h-52"
                          id="payButton"
                          //   onClick={placeOrderHandller}
                          onClick={() => {
                            if (
                              pageData?.responseData?.memberExists === "Y" ||
                              pageData?.createMemberSuccess
                            ) {
                              placeOrderHandller();
                            } else {
                              createMember();
                            }
                          }}
                        >
                          <b className="fs-16">
                            {pageData?.cartResponseData?.label?.complete_payment
                              ? pageData?.cartResponseData?.label
                                  ?.complete_payment
                              : "Complete Payment"}
                          </b>
                        </button>
                      );
                    } else {
                      return (
                        <button
                          className="btn btn-primary h-52"
                          id="payButton"
                          disabled="disabled"
                        >
                          <b className="fs-16">
                            {pageData?.cartResponseData?.label?.complete_payment
                              ? pageData?.cartResponseData?.label
                                  ?.complete_payment
                              : "Complete Payment"}
                          </b>
                        </button>
                      );
                    }
                  })()}
                </footer>
              </div>
            );
          } else {
            return <div />;
          }
        }
      })()}
    </main>
  );
};

export default PywGuestCustomSummary;
