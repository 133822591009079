import React,{ useEffect } from "react";
import { images } from "../pywImagesComponent/PywImages";
// import '../assets/styles/css/Main.styles.scss';
// import '../assets/styles/css/Spinner.styles.scss';
import {hideInfoBackdrop,hideInfoShowCitiBackdrop} from "../pywUtilComponent/PywUtil";


const PywInfomodelbackdrop =(props) =>  {

    useEffect(() => {}, []);
    return (
      <div id='infobackdrop' class="infobackdrop fixed-position-backdrop">
        <div class="modal">
            <div class="modal-header">
                <button class="btn btn-text closeModal" onClick={e => { hideInfoBackdrop(e) } }>
                    <img src={images.close} alt="Close" />
                </button>
            </div>
          <section class="modal-body">
            <iframe id="iframeInfo" src="" 
            width="100%" height="430px" frameBorder="0"></iframe>
          </section>
          <div class="modal-footer">
              <div class="flex center">
              
              {(() => {
                          if (props.btnflag==="backbtn") {
                            return (
                  <button class="btn btn-primary " id="payButton" onClick={e => { hideInfoBackdrop(e) } }>Back</button>)
              } else if(props.btnflag==="citibtn") return (<button class="btn btn-primary " id="payButton" onClick={e => { hideInfoShowCitiBackdrop(e) } }>Apply & Buy</button>)
              })() } 
              </div>
          </div>
        </div>
      </div>
    )
  
}
export default PywInfomodelbackdrop;
