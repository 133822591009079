import React from "react";
import { headerPyw } from "../../pywConstantComponent/PywDesignsConstant";
import { images } from "../../pywImagesComponent/PywImages";
import AnalyticsWrapper from "../../pywAnalytics/AnalyticsWrapper";
import { useSelector } from "react-redux";

const Pyw_ActivationEmailConfirmation = () => {
  const pageData = useSelector((state) => state.pageData);
  return (
    <main className="ff-open-sans flex-container activation-wrapper">
      <AnalyticsWrapper
        triggerType="page"
        event="activate_email_confimation"
        data={pageData}
      />
      {headerPyw(sessionStorage.getItem("merchantHeadline"))}
      <section className="container container-wrapper container-wrapper-without-padding p-05">
        <h3 className="center">Activation email sent</h3>
        <div class="center mt-18">
          <img src={images.rightGreen} alt="" />
        </div>
        <p className="center mt-18 fs-14 lh-20 grey-150">
          Check your email to activate your free Sears Home Advantage account!
        </p>
      </section>
    </main>
  );
};

export default Pyw_ActivationEmailConfirmation;
