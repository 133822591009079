import LoginButton from "../pywButtonComponent/LoginButton";
import { images } from "../pywImagesComponent/PywImages";

export function getTenderBody() {
  return (
    <section>
      <div className="container-wrapper-without-padding member-center-card pt-0">
        {brandMessageTender()}
        <div>
          <div className="syw-points-body-card">
            Your Brand Image
          </div>
        </div>
      </div>
    </section>
  );
}
export function getTenderFooter() {
  return (
    <footer className="container-wrapper-without-padding member-footer member-footer-margin mb-30 mt-88">
      <LoginButton type="primary">Sign up</LoginButton>
      <div className="separator">
        <span className="fs-14 fw-700 lh-20">or</span>
      </div>
      <LoginButton>
        <p>
          {" "}
          <b>Sign in</b>
        </p>
      </LoginButton>
    </footer>
  );
}
export function brandMessageTender(brandMessage) {
  return (
    <p className="mb-20 title-grey member-brand-msg fw-400 lh-24 center">
      Reward yourself , your way.
    </p>
  );
}
// export function getSywPointsCompletePayment(placeOrderHandller) {
//   return (
//     <footer className="container-wrapper-without-padding member-center-card btn-summary-continue mb-30 pb-0 pt-28">
//       <button
//         className="btn btn-primary member-footer mt-20"
//         id="payButton"
//         onClick={()=> placeOrderHandller()}
//       >
//         <b className="fw-700 fs-16 lh-20">Complete Payment</b>
//       </button>
//     </footer>
//   );
// }
