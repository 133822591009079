import React, {Component}from "react";
import {template} from "./Templates";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import AliceCarousel from 'react-alice-carousel';
import '../assets/styles/css/alice-carousel.styles.scss';
import AnalyticsWrapper from "../pywAnalytics/AnalyticsWrapper";
//import "react-alice-carousel/lib/alice-carousel.styles.scss";

class T1_Disable_Offer_Template extends Component {

    render() {
        return (
            <div className="description">
                {/* <PywTealiumAnalytics response={this.props.pageData} tealiumEvent="initial" /> */}
                <AnalyticsWrapper
					 
					triggerType="page"
					event="initial"
					data={this.props.pageData}
				/>
                <div className="initial-body pywinitial-card">
                <div className="initial-body-left-line-outer mb-8">
                    <AliceCarousel autoWidth="false" autoPlay="true"  autoPlayInterval="3000"
                    disableDotsControls={this.props.pageData.CMP_PayYourWay.offers.length>=2?false:true}
                    disableButtonsControls="true"
                    infinite={this.props.pageData.CMP_PayYourWay.offers.length>=2?true:false}>
                        {this.props.pageData.CMP_PayYourWay.offers.map(partsList => {
                            return (<div className="initial-body-left-line">
                                { partsList.parts.map(parts => {
                                    if (parts.part === 1) {
                                        return (template(parts))
                                    }
                                }) }

                                <p>
                                    { partsList.parts.map(parts => {
                                        if (parts.part === 2) {
                                            return (<p className="mb-4 width-16rem">{template(parts) } </p>)
                                        }
                                    }) }
                                    { partsList.parts.map(parts => {
                                        if (parts.part === 3) {
                                            return (<p><h5 className="mt-16 width-16rem"> { template(parts) } </h5></p>)
                                        }
                                    }) }
                                </p>

                            </div>);
                        }) }
                        
                    </AliceCarousel>
                    </div>
                    <div className="initial-body-right">
                        <p>
                            {this.props.pageData.CMP_PayYourWay.staticParts.map(parts => {
                                if (parts.part === 1) {
                                    return (<p className="pywinitial-sdk">{template(parts) }</p>)
                                }
                            }) }
                            {this.props.pageData.CMP_PayYourWay.staticParts.map(parts => {
                                if (parts.part === 2) {
                                    return (<h5>{template(parts,true)}</h5>)
                                }
                            }) }
                            {this.props.pageData.CMP_PayYourWay.staticParts.map(parts => {
                                if (parts.part === 3) {
                                    return (<p className="mt-8 fontSize0_858"> {template(parts) }</p>)
                                }
                            }) }
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default T1_Disable_Offer_Template;