import React, { useEffect, useState } from "react";
import {
  BROWSERNAME,
  headers,
  LOGIN,
  PYW_GUEST_CUSTOM_SUMMARY,
  PYW_MEMBER,
  TRUE,
} from "../../pywConstantComponent/PywConstant";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "../../pywUtilComponent/PywRedirect";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchPrepareDetails } from "../../redux";
import { loading } from "../../pywConstantComponent/PywDesignsConstant";
import PywError from "../../pywErrorComponent/PywError";
import { GTMAnalytics } from "../../pywAnalytics/pywGTMAnalytics/Pyw_GTM_Analytics";
import * as rdd from "react-device-detect";

const PywHomeGuestMember = (props) => {
  const pageData = useSelector((state) => state.pageData);
  const dispatch = useDispatch();
  const { user, isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const [GTMHit, setGTMHit] = useState(true);
  const [redirectToGuestCustom, setRedirectToGuestCustom] = useState(false);
  useEffect(async () => {
    const queryString = require("query-string");
    const values = queryString.parse(props.location.search);
    if (
      Object.keys(values).length === 0 ||
      !values.cartId ||
      !values.refid ||
      !values.merchant
    ) {
      setRedirectToGuestCustom(true);
    } else {
      const refid = values.refid;
      // const refid = window.opener === null ? encodeURIComponent(values.refid) : values.refid;
      const cartId = values.cartId;
      const sywr = values.sywr;
      BROWSERNAME.NAME = rdd.browserName;
      const merchantClientId = values.merchant;
      const intcmpID = values.intcmp;
      sessionStorage.setItem("pywmerchant", merchantClientId);
      sessionStorage.setItem("intcmpID", intcmpID);
      sessionStorage.setItem("pywrefid", refid);
      sessionStorage.setItem("pywcartId", cartId);
      var atpyw =
        Date.now() +
        "" +
        window.performance.timing.navigationStart * window.performance.now() +
        "" +
        Math.floor(Date.now() + Math.random() * Date.now());
      sessionStorage.setItem("at0pyw", atpyw);
      delete values.refid;
      delete values.cartId;
      delete values.merchant;
      logout({ returnTo: window.location.origin + window.location.pathname });
    }
  }, []);

  if (redirectToGuestCustom) {
    // handleAuthFlow();
    sessionStorage.setItem("merchantName", pageData.merchantName);
    return <Redirect to={PYW_GUEST_CUSTOM_SUMMARY} push={true} />;
  } else {
    return loading();
  }
};

export default PywHomeGuestMember;
