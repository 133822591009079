import React, { useState, useEffect, useRef } from "react";
import "./Pyw_SHSComponent.styles.scss"
import { images } from "../pywImagesComponent/PywImages";
import { useSelector, useDispatch } from "react-redux";
import cookie from "react-cookies";
import { generatePaymentId, getSdkContents } from "../redux";
import { Redirect } from "react-router-dom";
import { LOADING } from "../pywConstantComponent/PywConstant"
import PywError from"../pywErrorComponent/PywError";
import * as asyContain from "../redux/pywMemberDetails/pywMemberDetailsActions";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import { template } from "../pywInitialTemplateComponent/Templates";
import PywModalBackdrop from "../pywModalComponent/PywModalBackdrop"
import { handleModalPopup } from "../pywUtilComponent/PywUtil";
import AnalyticsWrapper from "../pywAnalytics/AnalyticsWrapper";

const Pyw_OfferPage = (props) => {
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  let pageData = useSelector(state => state.pageData);
  const [analEvent , setAnalEvent] = useState("contactless_offerload");
  const queryString = require("query-string");
  const values = queryString.parse(props.location.search);
  const txnid = values.txnid
  useEffect(() => {
    alert('Payment Offer!');
    const queryString = require("query-string");
    const values = queryString.parse(props.location.search);
    const merchantClientId = values.merchant;
    const txnid = values.txnid
    let headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      platform: "PYW",
      merchantClientId: merchantClientId,//TODO: replace it with merchant;
      txnId:txnid
    };

    var txndetails = {
      txnId:txnid
    }
    // pageData = Object.assign(pageData, txndetails)

    cookie.save('isECM', 'N',
      { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });

    if (merchantClientId != undefined) {
      cookie.save('pywmerchant', merchantClientId,
        { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });
      cookie.save('merchantName', merchantClientId,
        { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });
		  }

    const requestHeaders = { headers };
    let requestBody = {};
    dispatch(getSdkContents(requestBody, requestHeaders))
  }, []);

  const setEvenAlert = () => {
    setAnalEvent("contactless_offerload_decline");
    alert('Offer Declined!');
  }

  const handleContinueOffer = () => {
    const queryString = require("query-string");
    const values = queryString.parse(props.location.search);
    const totalDue = values.totalDue;
    const postbackUrl = values.postbackUrl;
    const merchantClientId = values.merchant;
    const transactionId = values.txnid
    const refid = values.refid

    let headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      platform: "PYW"
    };

    headers.merchantClientId = merchantClientId;
    headers.transactionId = transactionId;
    headers.refid = refid;

    if (cookie.load('pywrefid') === undefined) {
      if (refid !== undefined) {
        cookie.save('pywrefid', refid,
          { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });
      }
    } else if (cookie.load('pywrefid') !== refid) {
      cookie.save('pywrefid', refid,
        { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });
    }

    const requestHeaders = { headers };
    let requestBody = {};
    requestBody.totalDue = totalDue;
    requestBody.postbackUrl = postbackUrl;
    dispatch(generatePaymentId(requestBody, requestHeaders))
  }

  if (pageData.isValid) {
    cookie.save('pywid', pageData.pywid, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });
    return <Redirect to={`/shsdesc?id=${pageData.pmtid}`} push={true} />;
  } else if (pageData.contentValid && pageData.pageError) {
    alert("Invalid Payment Details or Invalid Session.");
    return <PywError pageData={pageData}/>;
  } else if (pageData.pageLoading) {
    return (
      <section class="container loading-card mt-32">
        <div class="loader" />
        <h5 class="mt-16">{LOADING}</h5>
      </section>)
  } else {
    return (
      <main className="main-container hs-offer-page-wrapper">
        <section>
          {/* <PywTealiumAnalytics response={pageData} tealiumEvent={analEvent} /> */}
          <AnalyticsWrapper
					 
					triggerType="page"
					event={analEvent}
					data={{
            ...pageData,
            txnId: txnid,
          }}
				/>
          <div class="container-grey">
            <p class="ff-open-sans center hs-offer-header mb-16">Join Shop Your Way, Apply for the <br/> Card and Pay with Shop Your Way
            </p>
            <div class="space-column" style={{ borderRadius: "10px" }}>
              {(() => {
                if (pageData.contentValid && Object.keys(pageData.response).length !== 0 && pageData.response.CMP_PayYourWay !== undefined) {
                  return (
                    <div>
                      {pageData.response.CMP_PayYourWay.offers.parts.map(parts => {
                        return (<div class='mb-16'><p>{template(parts) }</p></div>)
                      }) }
                    </div>
                  )
                } else {
                  return (
                    <div>
                      <div class='ff-open-sans mb-16'>
                        <p className="hs-offer-sb1">Get up to<span style={{ color: '#0066CC' }}> $225 </span><span style={{ color: '#262C2F' }}>in Statement Credits<span style={{ position: "relative"}}>&#8224; </span></span></p>
                      </div>
                      <div class='mb-16'>
                        <p className="gotham-font-style hs-shs-nsywao">New Shop Your Way Mastercard<sup>&#174; </sup> Accounts Only.</p>
                      </div>
                      <div class='mb-16'>
                        <p className="ff-open-sans hs-shs-desc">Earn a $75 statement credit for every $500 spent, up to $225, on eligible purchases<sup>&#8224; </sup> made wherever Mastercard<sup>&#174; </sup> is accepted in the first 90 days after you are approved for a new Shop Your Way Mastercard<sup>&#174; </sup><sup>&#8225; </sup>.Valid for new accounts opened 3/1/2024 - 3/31/2025.</p>
                      </div>
                      <div>
                        <p><a id="openModal" className="hs-shs-sde" onClick={e => { handleModalPopup(e, process.env.REACT_APP_HS_SEE_DETAILS_URL + "&p=PYW#terms") } }><sup className="hs-shs-op-sup">&#8224; </sup><span className="gotham-font-style" >See details and exclusions</span></a></p>
                      </div>
                      <div class='img-space'>
                        <img class="img-mastercard" src={process.env.REACT_APP_HS_IMG_MASTERCARD}/>
                        <img class="img-eclipse" src={process.env.REACT_APP_HS_IMG_ECLIPSE}/>
                      </div>
                    </div>
                  )
                }
              })() }
            </div>
            <button class="btn sh-btn-primary mt-24" onClick={handleContinueOffer}>
              <span className="gotham-font-style hs-shs-btn-yes-decline">Yes, Click here to continue</span>
            </button>
            <div class="gotham-font-style separator-nw"><span>or</span></div>
            <button class="btn btn-white mt-16 mb-32" onClick={setEvenAlert}>
              <span className="gotham-font-style hs-shs-btn-yes-decline btn-blk">Decline and use other options</span>
            </button>
          </div>
        </section>
        <PywModalBackdrop btnflag="backbtn" waitText/>
      </main>)
  }
}
export default Pyw_OfferPage;
