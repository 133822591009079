import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import './assets/scripts/scripts';
// import './assets/styles/css/Pyw-unify-money-main.styles.scss'

// import './assets/styles/css/Pyw-fusion.styles.scss'
// import './assets/styles/css/Pyw-syw-points.styles.scss'
// import './assets/styles/css/Pyw-main-3.0.styles.scss';

import './assets/styles/css/Main.styles.scss';

// import PywCheckout from './pywCheckoutComponent/PywCheckout';
// import "./assets/styles/css/Main.styles.scss"
import PywLearnMoreCheckout from './pywLearnMoreComponent/PywLearnMoreCheckout';
import PywLearnMoreMember from './pywLearnMoreComponent/PywLearnMoreMember';
import PywOfferPage from './pywSHSComponent/Pyw_OfferPage';
import Pyw_ECM from './pywSHSComponent/Pyw_ECM';
import Pyw_Description from './pywSHSComponent/Pyw_Description';
import Pyw_Confirmed from './pywSHSComponent/Pyw_Confirmed';
import Pyw_Cancelled from './pywSHSComponent/Pyw_Cancelled';
import Pyw_OnHold from './pywSHSComponent/Pyw_OnHold';
//import Pyw_10104 from './pywCheckoutComponent/Pyw_10104';
// import Pyw_10108 from './pywCheckoutComponent/Pyw_10108';
// import Pyw_10109 from './pywCheckoutComponent/Pyw_10109';
// import Pyw_10109_NOCC from './pywCheckoutComponent/Pyw_10109_NOCC';
// import Pyw_10208_NOCC from './pywCheckoutComponent/Pyw_10208_NOCC';
// import Pyw_10111 from './pywCheckoutComponent/Pyw_10111';
//import Pyw_10112 from './pywCheckoutComponent/Pyw_10112';
import Pyw_OrderConfirmation_ws from './pywCheckoutComponent/pywOrderStatusComponent/Pyw_OrderConfirmation_ws';
import PywInitialDisableButton from './pywSdkComponent/PywInitialDisableButton';
import PywInitial from './pywSdkComponent/PywInitial'
import PywInitialNoView from './pywCheckoutComponent/pywHomeComponent/PywInitialNoView'
import PywErrorPage from"./pywErrorComponent/PywErrorPage";
import Test from './test'
import { Provider } from 'react-redux'
import store from './redux/store'
import PywAuth0ProviderWithHistory from './pywAuth0ProviderComponent/PywAuth0ProviderWithHistory';
import PywProtectedRoute from './pywAuth0ProviderComponent/PywProtectedRoute';
import { Redirect } from "react-router-dom";
import Pyw_IncorrectDetails from './pywCheckoutComponent/pywLinkStatusComponent/Pyw_IncorrectDetails';
import Pyw_HomeServicesOrderConfirmation from './pywSHSComponent/Pyw_HomeServicesOrderConfirmation';
import PywLearnMoreSummary from './pywLearnMoreComponent/PywLearnMoreSummary';
import { closeWindow } from './pywUtilComponent/PywUtil';
import Pywguestsummary from './pywCheckoutComponent/pywSummaryComponent/PywGuestSummary'
import PywHomeComponent from './pywCheckoutComponent/pywHomeComponent/PywHomeComponent';
import PywValidationComponent from './pywCheckoutComponent/PywValidationComponent/PywValidationComponent';
import PywSummary from './pywCheckoutComponent/pywSummaryComponent/PywSummary'
import PywGuestcardDeclined from './pywCheckoutComponent/pywGuestCardDeclinedComponent/PywGuestCardDeclined'
import PywMemberComponent from './pywCheckoutComponent/pywMemberComponent/PywMemberComponent';
import PywCustomSummaryComponent from './pywCheckoutComponent/pywSummaryComponent/PywCustomSummaryComponent';
import PywGuestMemberTransferCartComponent from './pywCheckoutComponent/pywMiddlewareComponent/PywGuestMemberTransferCartComponent';
import PywReturnFromCityComponent from './pywCheckoutComponent/pywMiddlewareComponent/PywReturnFromCityComponent';
import PywApplyPoints from './pywCheckoutComponent/pywPointsComponent/PywApplyPoints'
import { PYW_ACTIVATION, PYW_CC_ERROR, PYW_CITI_CARD_DECLINED, PYW_CITI_RETURN, PYW_CLOSE, PYW_CONFIRMATION, PYW_CUSTOM_SUMMARY, PYW_ERROR, PYW_FUSION_VALIDATION, PYW_GUEST_CUSTOM_SUMMARY, PYW_GUEST_MEMBER, PYW_GUEST_MEMBER_HOME_PATH, PYW_GUEST_SUMMARY_PATH, PYW_HOME_PATH, PYW_MEMBER, PYW_POINTS, PYW_REG_MEMBER_HOME_PATH, PYW_SUMMARY } from './pywConstantComponent/PywConstant';
import Pyw_ActivationEmailConfirmation from './pywCheckoutComponent/pywOrderStatusComponent/Pyw_ActivationEmailConfirmation';
import PywHomeRegisteredMember from './pywCheckoutComponent/pywHomeComponent/PywHomeRegisteredMember';
import Pyw_UniversalPaymentConfirm from './pywCheckoutComponent/pywOrderStatusComponent/Pyw_UniversalPaymentConfirm';
import PywHomeGuestMember from './pywCheckoutComponent/pywHomeComponent/PywHomeGuestMember';
import PywGuestCustomSummary from './pywCheckoutComponent/pywSummaryComponent/PywGuestCustomSummary';
// import PywLMHomeComponent from './pywLearnMoreComponent/pywLMHomeComponent/PywLMHomeComponent';
// import PywLMMemberComponent from './pywLearnMoreComponent/pywLMMemberComponent/PywLMMemberComponent';
// import PywLMDeclinedComponent from './pywLearnMoreComponent/pywLMDeclinedComponents/PywLMDeclinedComponent';
// import PYWLMSummaryComponent from "./pywLearnMoreComponent/pywLMSummaryComponent/PywLMSummaryComponent"
// import PYWLMCitiReturnSummaryComponent from './pywLearnMoreComponent/pywLMSummaryComponent/PYWLMCitiReturnSummaryComponent';
// import PYWLMGuestCitiReturnSummaryComponent from './pywLearnMoreComponent/pywLMSummaryComponent/PYWLMGuestCitiReturnSummaryComponent';
const routing = (
  <Provider store={store}>
    <PywAuth0ProviderWithHistory>
      <Router>
        <Switch>
          {/* //main components -- start */}
          <Route exact path="/shs" component={PywOfferPage}/>
          <Route exact path="/shsecm" component={Pyw_ECM}/>
          <Route exact path="/shsdesc" component={Pyw_Description}/>
          <Route exact path="/shsstatus" component={Pyw_Confirmed}/>
          <Route exact path="/shscanstatus" component={Pyw_Cancelled}/>
          <Route exact path="/shsonhold" component={Pyw_OnHold}/>
          <Route exact path="/test" component={Test}/>
          <Route exact path="/pyw/order/cl/confirmation" component={Pyw_HomeServicesOrderConfirmation}/>
          <Route exact path="/pyw/order/sha/confirmation" component={Pyw_UniversalPaymentConfirm}/>
           <Route exact path="/pyw/disinitial" component={PywInitialDisableButton} />
          
          //main components---end
          <Route exact path="/pywInitial" component={PywInitial}/>
          <Route exact path="/pmt" component={PywInitialNoView}/>
          {/* PYW WEB 3.2 */}
          <Route exact path={PYW_GUEST_SUMMARY_PATH} component={Pywguestsummary}/>
          <Route exact path={PYW_HOME_PATH} component={PywHomeComponent}/>
          <Route exact path={PYW_REG_MEMBER_HOME_PATH} component={PywHomeRegisteredMember}/>
          <Route exact path={PYW_GUEST_MEMBER_HOME_PATH} component={PywHomeGuestMember}/>
          <Route exact path={PYW_FUSION_VALIDATION} component={PywValidationComponent}/>
          <PywProtectedRoute exact path={PYW_SUMMARY} component={PywSummary}/>
          <PywProtectedRoute exact path={PYW_MEMBER} component={PywMemberComponent} />
          <Route exact path={PYW_CITI_CARD_DECLINED} component={PywGuestcardDeclined}/>
          <PywProtectedRoute exact path={PYW_CUSTOM_SUMMARY} component={PywCustomSummaryComponent} />
          <Route exact path={PYW_GUEST_CUSTOM_SUMMARY} component={PywGuestCustomSummary}/>
          {/* <PywProtectedRoute exact path={PYW_GUEST_MEMBER} component={PywGuestMemberTransferCartComponent} /> */}
          <Route exact path={PYW_CITI_RETURN} component={PywReturnFromCityComponent} />
          <PywProtectedRoute exact path={PYW_CC_ERROR} component={Pyw_IncorrectDetails}/>
          <Route exact path="/"><Redirect to={PYW_HOME_PATH}/></Route>
          <PywProtectedRoute exact path={PYW_POINTS} component={PywApplyPoints}/>
          <Route exact path={PYW_CONFIRMATION} component={Pyw_OrderConfirmation_ws}/>
          <Route exact path={PYW_ERROR} component={PywErrorPage} />
          <Route exact path={PYW_CLOSE} component={()=>{closeWindow()}} />
          <Route exact path={PYW_ACTIVATION} component={Pyw_ActivationEmailConfirmation}/>

          {/* <Route exact path={"/pyw/temp"} component={Pyw_UniversalPaymentConfirm}/> */}
          
          {/* Fusion first 3.2 LM */}
          {/* <Route exact path={PYW_LM_HOME} component={PywLMHomeComponent} />
          <PywProtectedRoute exact path={PYW_LM_MEMBER} component={PywLMMemberComponent} />
          <PywProtectedRoute exact path={PYW_LM_DECLINED} component={PywLMDeclinedComponent} />
          <PywProtectedRoute exact path={PYW_LM_SUMMARY} component={PYWLMSummaryComponent} />
          <PywProtectedRoute exact path={PYW_LM_CITI_RETURN_SUMMARY} component={PYWLMCitiReturnSummaryComponent} />
          <Route exact path={PYW_LM_GUEST_CITI_RETURN_SUMMARY} component={PYWLMGuestCitiReturnSummaryComponent} /> */}

    
        </Switch>
      </Router>
    </PywAuth0ProviderWithHistory>
  </Provider>
);
ReactDOM.render(routing, document.getElementById("root"));
