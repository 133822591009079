import {SUBSCRIPTION} from "../pywConstantComponent/PywConstant"
import { loginHandler } from "../pywUtilComponent/PywLoginUtil";
import { parseDollarValuetoPoints, parseDollarValue } from "../pywUtilComponent/PywUtil";
import { loading } from "./PywDesignsConstant";


//-------------Save Card scenarios-----------------------------
export function SAVECC(pageData) {
    if(parseFloat(pageData.summary.paymentDue) === 0 && pageData.addlAttrs.payType && pageData.addlAttrs.payType.startsWith(SUBSCRIPTION))
        return <h4 className="mb-20 fs-16 fw-400 title-grey mt-40">Please enter credit card details for subscription renewal payment.</h4>;
        
    else
        return <h4 className="mb-12 fs-16 fw-400 title-grey">Pay the remaining <span className="semi-light-blue">{pageData.summary.currency + pageData.summary.paymentDue}</span> on your card to complete your purchase.</h4>;
}

export const FULL_PAY_POINTS=<h4 className="mb-20 fs-16 fw-500 title-grey mt-40">No additional credit card payment necessary.</h4>;


//-------------Custom Card scenarios-----------------------------
export function CUSTOMCC(pageData) {
    if(pageData?.tenderTypes?.includes("CC_DC") && (parseFloat(pageData.summary.paymentDue) === 0 && pageData?.addlAttrs?.payType && pageData?.addlAttrs?.payType?.startsWith(SUBSCRIPTION)))
        return <h4 className="mt-20 fs-17 fw-400 lh-20 title-grey">Please enter credit card details for subscription renewal payment.</h4>;

    else if( pageData?.tenderTypes?.includes("CC_DC") && ((pageData.summary.priceSubTotal > parseDollarValue(pageData?.sywPoints?.currentPointsBalance))))
        return <h4 className="mt-16 fs-16 fw-400 lh-20 title-grey">Use a credit card to pay the remaining <span className="semi-light-blue">{pageData.summary.currency + pageData.summary.paymentDue}</span> and complete your purchase.</h4>;
    
    else if(((pageData.summary.priceSubTotal <= parseDollarValue(pageData?.sywPoints?.currentPointsBalance)) &&   pageData.addlAttrs.payType && pageData.addlAttrs.payType.startsWith(SUBSCRIPTION)) )
        return <h4 className="mt-20 fs-17 fw-400 lh-20 title-grey">Use your Shop Your Way points to pay the balance of <span className="semi-light-blue">{pageData.summary.currency + pageData.summary.paymentDue}</span> and please enter credit card details for subscription renewal payment.</h4>;
    
    else if(pageData?.tenderTypes?.includes("SYW_POINTS") && (parseFloat(pageData.summary.paymentDue) !== 0 && (pageData.summary.priceSubTotal <= parseDollarValue(pageData?.sywPoints?.currentPointsBalance)) && (!pageData.addlAttrs.payType || !pageData.addlAttrs.payType.startsWith(SUBSCRIPTION))))
        return <h4 className="mt-20 fs-16 fw-400 lh-20 title-grey">Use your Shop Your Way points to complete your order of <span className="semi-light-blue">{pageData.summary.currency +  pageData.summary.priceSubTotal}</span>.</h4>;

    else if(pageData?.tenderTypes?.includes("SYW_POINTS") && (parseFloat(pageData.summary.paymentDue) === 0 && (!pageData.addlAttrs.payType || !pageData.addlAttrs.payType.startsWith(SUBSCRIPTION))))
        return <h4 className="mt-20 fs-16 fw-400 lh-20 title-grey">Use your Shop Your Way points to complete your order of <span className="semi-light-blue">{pageData.summary.currency +  pageData.summary.priceSubTotal}</span>.</h4>;
}

export function LOGINREQUEST(pageData, loginWithRedirect, dispatch) {
    let emailId = "";
        return <h4 className="mb-16 fs-16 fw-400 title-grey"> Remember to <a className="semi-light-blue" onClick={e =>  {
            {pageData.ffm.map(ffm => {
                if ("EMAIL" === ffm.type) {
                    emailId = ffm.emailId;
                }
            })}
            loading();
            loginWithRedirect({ screen_hint: "login" , login_hint:emailId})
        }
        }>Login</a> to use your points in this purchase.</h4>;
}
