import React, { useState, useEffect } from "react";
import "./PywSummary.styles.scss";
import postscribe from "postscribe";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPrepareDetails,
  fetchPlaceOrder,
  fetchPlaceOrderAndUpdateCard,
  addSubscription,
} from "../../redux";
import { useAuth0 } from "@auth0/auth0-react";
import PywError from "../../pywErrorComponent/PywError";
import Payment from "payment";
import * as duui from "device-uuid";
import {
  headerBar,
  headerPyw,
  headerUserWithPoints,
  headerUserWithPointsGuest,
  progressBar,
} from "../../pywConstantComponent/PywGuestDesignConstant";
import PywCommonSummary from "./PywCommonSummary";
import {
  PYWSUMMARY,
  PYW_CUSTOM_SUMMARY,
  TRUE,
} from "../../pywConstantComponent/PywConstant";
import PywTealiumAnalytics from "../../pywAnalytics/PywTealiumAnalytics";
import {
  loading,
  validatePayment,
} from "../../pywConstantComponent/PywDesignsConstant";
import { Redirect } from "../../pywUtilComponent/PywRedirect";
// import { Redirect } from "react-router-dom";
import cookie from "react-cookies";
import AnalyticsWrapper from "../../pywAnalytics/AnalyticsWrapper";
import { GTMAnalytics } from "../../pywAnalytics/pywGTMAnalytics/Pyw_GTM_Analytics";
import { getMerchantDetails } from "../../pywUtilComponent/PywUtil";
const Pywguestsummary = () => {
  const pageData = useSelector((state) => state.pageData);
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const [saveCcardId, setSaveCcardId] = useState("");
  const { user } = useAuth0();
  const [userInfo, setUserInfo] = useState("");
  useEffect(async () => {
    const refid = sessionStorage.getItem("pywrefid");
    const userJson = JSON.stringify(user);
    const userNew = userJson.split(process.env.REACT_APP_CLAIMS_URL).join("");
    const user_json = JSON.parse(userNew);
    setUserInfo(user_json);
    const items = [];
    let requestBody = { items };
    const headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform: "PYW",
    };
    headers.cartId = sessionStorage.getItem("pywcartId");
    headers.refid = sessionStorage.getItem("pywrefid");
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");

    headers.pywstate =
      PYWSUMMARY +
      "~~" +
      sessionStorage.getItem("at0pyw") +
      duui.DeviceUUID().get();
    const requestHeaders = {
      headers,
    };
    // var rtocken = '<script type="text/javascript" src="https://r0.shopyourway.com/w/profile"> </script>';
    // postscribe("#rtockenpost", rtocken);
    dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));
    await getMerchantDetails(dispatch);
  }, []);

  useEffect(() => {
    if (
      pageData.orderConfirmationResponseCode !== undefined &&
      pageData.orderConfirmationResponseCode !== "" &&
      process.env.REACT_APP_GTM_ANALYTICS === TRUE
    ) {
      var cartid = sessionStorage.getItem("pywcartId");
      var refid = sessionStorage.getItem("pywrefid");
      var merchant = sessionStorage.getItem("pywmerchant");

      GTMAnalytics("page", "place_order", {
        ...pageData,
        checkoutStep: 3,
        cartId: cartid,
        merchantName: merchant,
        merchant_transaction_id: pageData?.txnId,
        ...pageData.responseData,
      });
    }
  }, [pageData]);

  useEffect(() => {
    if (pageData.isValid) {
      GTMAnalytics("page", "checkout", {
        ...pageData,
        checkoutStep: 3,
      });
    }
  }, [pageData.isValid]);
  return (
    <main
      id="rtockenpost"
      className="ff-open-sans flex-container summary-wrapper"
    >
      {(() => {
        if (
          pageData.orderConfirmationResponseCode !== undefined &&
          pageData.orderConfirmationResponseCode !== ""
        ) {
          var cartid = sessionStorage.getItem("pywcartId");
          var refid = sessionStorage.getItem("pywrefid");
          var merchant = sessionStorage.getItem("pywmerchant");
          sessionStorage.removeItem("pywcartId");
          sessionStorage.removeItem("pywrefid");
          return (
            <div>
              {process.env.REACT_APP_TEALIUM_ANALYTICS === TRUE && (
                <PywTealiumAnalytics
                  response={pageData}
                  tealiumEvent="place_order"
                />
              )}

              {/* <PywTealiumAnalytics response={pageData} tealiumEvent="place_order" /> */}
              {/* <AnalyticsWrapper
					 
					triggerType="page"
					event="place_order"
					data={{
            ...pageData,
            checkoutStep: 3,
            cartId: cartid,
            merchantName: merchant,
            merchant_transaction_id: pageData?.txnId,
            ...pageData.responseData
    }}
				/> */}
              {loading()}
              {(() => {
                if (
                  pageData.responseData.redirect !== undefined &&
                  pageData.responseData.redirect === "_self"
                ) {
                  if (pageData.confirmStatus === "interim-order") {
                    setTimeout(() => {
                      window.location.replace(
                        pageData?.responseData?.returnUrl +
                          "?pywid=" +
                          cartid +
                          "&pywrefid=" +
                          refid +
                          "&merchant=" +
                          merchant +
                          "&pywmsg=Y"
                      );
                    }, "2000");
                  } else {
                    setTimeout(() => {
                      window.location.replace(
                        pageData?.responseData?.returnUrl +
                          "?pywid=" +
                          cartid +
                          "&pywrefid=" +
                          refid +
                          "&merchant=" +
                          merchant +
                          "&pywmsg=N"
                      );
                    }, "2000");
                  }
                } else {
                  setTimeout(() => {
                    window.close();
                  }, "2000");
                }
              })()}
            </div>
          );
        } else {
          if (navigate) {
            return <Redirect to="/pyw/points" push={true} />;
          } else if (pageData.pageError) {
            return <PywError pageData={pageData} />;
          } else if (pageData.validating) {
            return validatePayment(pageData.merchantHeadline);
          } else if (pageData.pageLoading) {
            return loading();
          } else if (pageData.merchantDetailsLoading) {
            return loading();
          } else if (pageData.isValid) {
            if (
              window.opener &&
              !window.opener.closed &&
              pageData.addlAttrs?.redirect !== undefined &&
              pageData.addlAttrs?.redirect === "_self"
            ) {
              //sessionStorage.setItem('_popupflag', "true");
              cookie.save("_popupflag", "true", {
                maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE),
                domain: ".shopyourway.com",
                path: "/",
              });
              window.close();
            }
            if (
              pageData?.ccDc?.savedCards == undefined ||
              pageData?.ccDc?.savedCards.length <= 0
            ) {
              return <Redirect to={PYW_CUSTOM_SUMMARY} push={true} />;
            }
            return (
              <section>
                {headerPyw(pageData.merchantHeadline)}
                {/* <PywTealiumAnalytics response={pageData} tealiumEvent="checkout" /> */}
                {process.env.REACT_APP_TEALIUM_ANALYTICS === TRUE && (
                  <PywTealiumAnalytics
                    response={pageData}
                    tealiumEvent="checkout"
                  />
                )}
                {/* <AnalyticsWrapper
									triggerType="page"
									event="checkout"
									data={{
										...pageData,
										checkoutStep: 3,
									}}
								/> */}
                <PywError pageData={pageData} />
                {headerUserWithPoints(pageData, userInfo)}
                {headerBar(2, pageData, setNavigate)}

                <div className="container-wrapper-without-padding pl-0 pr-0">
                  {(() => {
                    if (
                      pageData?.ccDc?.savedCards !== undefined &&
                      pageData?.ccDc?.savedCards.length >= 0 &&
                      pageData?.ccDc?.savedCards[0].cvvRequired !== "Y" &&
                      pageData?.ccDc?.savedCards[0].expiryRequired !== "Y"
                    ) {
                      return (
                        <div className="progress-bar-summery mt-20">
                          {progressBar(2)}
                        </div>
                      );
                    }
                  })()}

                  <PywCommonSummary pageData={pageData}></PywCommonSummary>
                </div>
              </section>
            );
          }
        }
      })()}
    </main>
  );
};

export default Pywguestsummary;
