import React, { useState, useEffect } from "react";
import "./PywSummary.styles.scss";
import postscribe from "postscribe";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPrepareDetails,
  fetchPlaceOrder,
  fetchPlaceOrderAndUpdateCard,
  addSubscription,
} from "../../redux";
import { useAuth0 } from "@auth0/auth0-react";
import PywError from "../../pywErrorComponent/PywError";
import Payment from "payment";
import * as duui from "device-uuid";
import {
  GuestHeaderBar,
  headerBar,
  headerPyw,
  headerUserNameWithGuest,
  headerUserWithPoints,
  headerUserWithPointsGuest,
  progressBar,
} from "../../pywConstantComponent/PywGuestDesignConstant";
import PywCommonSummary from "./PywCommonSummary";
import {
  PYWGUESTSUMMARY,
  PYW_CITI_CARD_DECLINED,
  PYW_CUSTOM_SUMMARY,
  PYW_GUEST_CUSTOM_SUMMARY,
} from "../../pywConstantComponent/PywConstant";
import PywTealiumAnalytics from "../../pywAnalytics/PywTealiumAnalytics";
import { loading } from "../../pywConstantComponent/PywDesignsConstant";
import { Redirect } from "../../pywUtilComponent/PywRedirect";
import cookie from "react-cookies";
import AnalyticsWrapper from "../../pywAnalytics/AnalyticsWrapper";
import { getMerchantDetails } from "../../pywUtilComponent/PywUtil";
// import { Redirect } from "react-router-dom";
const PywguestSummary = () => {
  const pageData = useSelector((state) => state.pageData);
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const [saveCcardId, setSaveCcardId] = useState("");
  const { user, isAuthenticated } = useAuth0();
  const [userInfo, setUserInfo] = useState("");
  const { loginWithRedirect } = useAuth0();
  useEffect(async () => {
    const refid = sessionStorage.getItem("pywrefid");
    const items = [];
    let requestBody = { items };
    const headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform: "PYW",
    };
    headers.cartId = sessionStorage.getItem("pywcartId");
    headers.refid = sessionStorage.getItem("pywrefid");
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");

    headers.pywstate =
      PYWGUESTSUMMARY +
      "~~" +
      sessionStorage.getItem("at0pyw") +
      duui.DeviceUUID().get();
    const requestHeaders = {
      headers,
    };
    // var rtocken = '<script type="text/javascript" src="https://r0.shopyourway.com/w/profile"> </script>';
    // postscribe("#rtockenpost", rtocken);
    dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));
    await getMerchantDetails(dispatch);
  }, [isAuthenticated]);
  return (
    <main id="rtockenpost" className="ff-open-sans flex-container">
      {(() => {
        if (
          pageData.orderConfirmationResponseCode !== undefined &&
          pageData.orderConfirmationResponseCode !== ""
        ) {
          var cartid = sessionStorage.getItem("pywcartId");
          var refid = sessionStorage.getItem("pywrefid");
          var merchant = sessionStorage.getItem("pywmerchant");
          sessionStorage.removeItem("pywcartId");
          sessionStorage.removeItem("pywrefid");
          return (
            <div>
              {/* <PywTealiumAnalytics response={pageData} tealiumEvent="place_order" /> */}
              <AnalyticsWrapper
                triggerType="page"
                event="place_order"
                data={{
                  ...pageData,
                  ...pageData.responseData,
                }}
              />
              {loading()}
              {(() => {
                if (
                  pageData.responseData.redirect !== undefined &&
                  pageData.responseData.redirect === "_self"
                ) {
                  if (pageData.confirmStatus === "interim-order") {
                    setTimeout(() => {
                      window.location.replace(
                        pageData?.responseData?.returnUrl +
                          +"?pywid=" +
                          cartid +
                          "&pywrefid=" +
                          refid +
                          "&merchant=" +
                          merchant +
                          "&pywmsg=Y"
                      );
                    }, "2000");
                  } else {
                    setTimeout(() => {
                      window.location.replace(
                        pageData?.responseData?.returnUrl +
                          +"?pywid=" +
                          cartid +
                          "&pywrefid=" +
                          refid +
                          "&merchant=" +
                          merchant +
                          "&pywmsg=N"
                      );
                    }, "2000");
                  }
                } else {
                  setTimeout(() => {
                    window.close();
                  }, "2000");
                }
              })()}
            </div>
          );
        } else {
          if (isAuthenticated) {
            return <Redirect to={PYW_CUSTOM_SUMMARY} push={true} />;
          }
          if (navigate) {
            return <Redirect to="/pyw/points" push={true} />;
          } else if (pageData.pageError) {
            return <PywError pageData={pageData} />;
          } else if (
            pageData.pageLoading ||
            pageData.isDisableButtonClick ||
            pageData.merchantDetailsLoading
          ) {
            return loading();
          } else if (pageData.isValid) {
            if (
              window.opener &&
              !window.opener.closed &&
              pageData.addlAttrs.redirect !== undefined &&
              pageData.addlAttrs.redirect === "_self"
            ) {
              //sessionStorage.setItem('_popupflag', "true");
              cookie.save("_popupflag", "true", {
                maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE),
                domain: ".shopyourway.com",
                path: "/",
              });
              window.close();
            }

            if (
              pageData.ccDc.savedCards == undefined ||
              pageData.ccDc.savedCards.length <= 0
            ) {
              return <Redirect to={PYW_CITI_CARD_DECLINED} push={true} />;
            }

            return (
              <section>
                {headerPyw(pageData.merchantHeadline)}
                {/* <PywTealiumAnalytics response={pageData} tealiumEvent="checkout" /> */}
                <AnalyticsWrapper
                  triggerType="page"
                  event="checkout"
                  data={pageData}
                />
                <PywError pageData={pageData} />
                {GuestHeaderBar(pageData, loginWithRedirect, dispatch)}
                <div className="container-wrapper-without-padding pl-0 pr-0">
                  <div className="progress-bar-summery mt-20">
                    {progressBar(2)}
                  </div>
                  <PywCommonSummary pageData={pageData}></PywCommonSummary>
                </div>
              </section>
            );
          }
        }
      })()}
    </main>
  );
};

export default PywguestSummary;
