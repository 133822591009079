import React, {Component} from 'react'
import lock from '../assets/images/Lock.svg';
import sywLogo from '../assets/images/SYW_logo_black.svg';
import {images} from '../pywImagesComponent/PywImages';
import {openPopup} from "../pywUtilComponent/PywCitiHub";
import T1_Disable_Offer_Template from "../pywInitialTemplateComponent/T1_Disable_Offer_Template";
import T1_Disable_StaticParts_Template from "../pywInitialTemplateComponent/T1_Disable_StaticParts_Template";
import T2_Disable_StaticParts_Template from "../pywInitialTemplateComponent/T2_Disable_StaticParts_Template";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import axios from "axios";
import "./PywSdkComponent.styles.scss"

class PywInitialDisableDynamic extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageData: '',
    };
  }

  componentDidMount() {
    var element = document.querySelector(".initial");
    element.style.backgroundColor = this.props.pageData.CMP_PayYourWay.background;
  }
  render() {
    if (this.props.pageData.CMP_PayYourWay.template === "T1") {
      if (this.props.pageData.CMP_PayYourWay.offers !== undefined) {
        return (<T1_Disable_Offer_Template pageData={this.props.pageData}/>)
      } else {
        return (<T1_Disable_StaticParts_Template pageData={this.props.pageData}/>)
      }
    }else if(this.props.pageData.CMP_PayYourWay.template === "T2") {
      return (<T2_Disable_StaticParts_Template pageData={this.props.pageData}/>)
    }else return <div/>
  }
}
export default PywInitialDisableDynamic;
